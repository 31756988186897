import React from 'react';
import Button, { ButtonStyle } from '../../common/components/Button';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import AgentV2 from '../../components/modal/AgentV2';
import Preloader from '../../components/new_designs/Preloader';
import { DeviceHeightScrollContainer } from '@flk/design-system';
import { Tooltip } from 'react-tooltip';
import { ClientType, ExchangeOfContracts } from '../../types/ExchangeOfContracts';

import styles from './CommonWelcome.module.scss';

export type EocCommonWelcomeProps = {
    isLoading?: boolean;
    document: ExchangeOfContracts;
    clientName?: string;
    clientType?: ClientType;
    handleGetStarted: () => void;
    isLoadingGetStarted?: boolean;
    shouldVerifyIdentity?: boolean;
    shouldFillOutRequiredInfo?: boolean;
};

const EocCommonWelcome: React.FC<EocCommonWelcomeProps> = ({
    isLoading,
    document,
    clientName,
    clientType,
    handleGetStarted,
    isLoadingGetStarted,
    shouldVerifyIdentity,
    shouldFillOutRequiredInfo,
}) => {
    // const SHOW_DOWNLOAD_BUTTON = !shouldVerifyIdentity;
    // For now, we will hide the download button. We will need this in future
    const SHOW_DOWNLOAD_BUTTON = false;

    const greeting = clientName ? `Hi ${clientName},` : 'Hi,';

    const signingProcess = ['Review your documents', 'Sign only once'];
    if (shouldFillOutRequiredInfo) {
        signingProcess.unshift('Fill out required info');
    }
    if (shouldVerifyIdentity) {
        signingProcess.unshift('Verify your identity');
    }

    return isLoading ? (
        <DeviceHeightScrollContainer className={styles.loadingContainer}>
            <Preloader className={styles.spinner} />
        </DeviceHeightScrollContainer>
    ) : (
        <DeviceHeightScrollContainer className={styles.container}>
            <AgentV2 className={styles.agentInfo} agent={document?.agent} agency={document?.agency} />
            <div className={styles.content}>
                <div className={styles.greeting}>{greeting}</div>
                <p className={styles.documentText}>
                    Below is the <strong>Contract of Sale</strong> for you to review:
                </p>
                <div className={styles.documentSection}>
                    {document?.uploadedDocuments?.map((uploadedDocument, index) => (
                        <div key={index} className={styles.document}>
                            <Icon icon={Icons.FLK_DOCUMENT} className={styles.documentIcon} />
                            <p>Contract of Sale for {document.address}</p>
                            {SHOW_DOWNLOAD_BUTTON && (
                                <Button
                                    className={styles.download}
                                    buttonStyle={ButtonStyle.ICON}
                                    onClick={() => {
                                        window.open(
                                            `${SERVICE_URL}/api/mobile-document/custom-document-preview-inline/${document.id}/${uploadedDocument.id}`
                                        );
                                    }}
                                >
                                    <Icon className={styles.downloadIcon} icon={Icons.DOWNLOAD} />
                                </Button>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.signingProcess}>
                    <p>Signing Process</p>
                    {signingProcess.map((step, index) => (
                        <div key={index} className={styles.step}>
                            <div className={styles.number}>{index + 1}</div>
                            {step}
                        </div>
                    ))}
                </div>
                <div className={styles.text}>
                    <p>
                        If you need assistance, click on the{' '}
                        <strong
                            className={styles.dottedUnderline}
                            data-tooltip-id="info"
                            data-tooltip-content="Located on the top right of the screen"
                        >
                            <em>Info</em>
                        </strong>{' '}
                        icon for my <strong>Contact Details</strong>.
                    </p>
                    <Tooltip id="info" place="bottom" />
                    <p>
                        Regards,
                        <br />
                        <strong>{document.agent.firstName}</strong> from{' '}
                        <strong>{document.agency.details.agencyName}</strong>
                    </p>
                </div>
                <div className={styles.footer}>
                    <Button buttonStyle={ButtonStyle.CARD} className={styles.button} onClick={handleGetStarted}>
                        Get Started
                        {isLoadingGetStarted && <div className={styles.getStartedSpinner} />}
                    </Button>
                    <p className={styles.disclaimer}>
                        By clicking <em>Get Started</em>, I agree to FLK it over's{' '}
                        <strong>
                            <a
                                href="http://www.flkitover.com/terms-conditions/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms and Conditions
                            </a>
                        </strong>{' '}
                        and{' '}
                        <strong>
                            <a
                                href="http://www.flkitover.com/privacy-policy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </strong>
                        .
                    </p>
                </div>
            </div>
        </DeviceHeightScrollContainer>
    );
};

export default EocCommonWelcome;
