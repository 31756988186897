import React from 'react';
import Button, { ButtonStyle } from './Button.js';

import cx from 'classnames';
import styles from './ToggleButton.module.scss';

type ToggleButtonProps = {
    className?: string;
    firstButtonLabel: string;
    secondButtonLabel: string;
    isSecondButtonSelected: boolean;
    setSecondButtonSelected: (isSecondButtonSelected: boolean) => void;
};

const ToggleButton = ({
    className,
    firstButtonLabel,
    secondButtonLabel,
    isSecondButtonSelected,
    setSecondButtonSelected,
}: ToggleButtonProps) => {
    return (
        <div className={cx(styles.toggleButton, className)}>
            <Button
                buttonStyle={ButtonStyle.SECONDARY}
                className={cx(styles.button, { [styles.selected]: !isSecondButtonSelected })}
                onClick={() => setSecondButtonSelected(false)}
                disabled={false}
            >
                {firstButtonLabel}
            </Button>
            <Button
                buttonStyle={ButtonStyle.SECONDARY}
                className={cx(styles.button, { [styles.selected]: isSecondButtonSelected })}
                onClick={() => setSecondButtonSelected(true)}
                disabled={false}
            >
                {secondButtonLabel}
            </Button>
        </div>
    );
};

export default ToggleButton;
