import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { useParams } from 'react-router';
import { getDocumentClient, getDocumentToSign, getSteps } from '@app/selectors/eoc';
import hashHistory from '@app/history';
import { defaultAxiosInstance } from '@app/actions';
import { useGeneratingPdf } from './hooks/useGeneratingDocument';
import { PDF_GEN_PROCESSING_WITH_CLIENT_PLACEHOLDERS, RESPONDENT_TYPE_CLIENT } from '@app/config';
import { setErrorMessage } from '@app/actions/main';
import { isValidWinAnsi } from '@app/validate';
import DocumentHeader from '@app/components/new_designs/DocumentHeader';
import Card, { CardShadow, CardStyles } from '@app/componentLibrary/Card/Card';
import SectionHeader from '@app/components/new_designs/SectionHeader';
import Note from '@app/components/new_designs/Note';
import Typography, { TypographyTypes } from '@app/components/new_designs/Typography';
import TextInput from '@app/componentLibrary/TextInput/TextInput';
import Button, { ButtonStyle } from '@app/common/components/Button';
import { EocSigningSteps } from '@app/types/ExchangeOfContracts';

import styles from './ClientCustomPlaceholdersForm.module.scss';

const ClientCustomPlaceholdersForm = () => {
    const dispatch = useDispatch();
    const documentToSign = useSelector(getDocumentToSign);
    const steps = useSelector(getSteps);
    const client = useSelector(getDocumentClient);

    const { token } = useParams<{ token: string }>();

    const { isGeneratingPdf, generationStarted, generationFailed } = useGeneratingPdf({
        token,
        document: documentToSign ?? undefined,
        initialState: documentToSign?.pdfGeneratedState === PDF_GEN_PROCESSING_WITH_CLIENT_PLACEHOLDERS,
        onError: (error?: string) => {
            if (error) {
                dispatch(setErrorMessage(error));
            }
            hashHistory.push('/error');
        },
    });

    const handleSubmit = (values: any) => {
        generationStarted();

        defaultAxiosInstance
            .post(`/api/mobile-document/confirm-eoc-client-placeholders`, {
                documentId: documentToSign?.id,
                clientId: client?.id,
                customPlaceholders: Object.keys(values).map((key) => ({ id: key, value: values[key] })),
                token,
            })
            .catch((err: any) => {
                generationFailed(err?.response?.data?.error);
            });
    };

    const clientCustomPlaceholders =
        documentToSign?.customPlaceholders?.filter((p) => p.respondentType === RESPONDENT_TYPE_CLIENT) || [];

    const validate = (values: any) => {
        const errors: any = {};
        clientCustomPlaceholders?.forEach((placeholder) => {
            if (!values[placeholder.id]) {
                errors[placeholder.id] = 'Required';
            }
            if (!isValidWinAnsi(values[placeholder.id])) {
                errors[placeholder.id] = 'Invalid character';
            }
        });

        return errors;
    };

    const initialValues: any = {};
    clientCustomPlaceholders.forEach((p) => {
        initialValues[p.id] = p.value;
    });

    if (!documentToSign) {
        return null;
    }

    return (
        <div className={cx(styles.clientCustomPlaceholders, { [styles.loading]: isGeneratingPdf })}>
            <DocumentHeader
                useAgentV2
                agentV2Props={{
                    agent: documentToSign?.agent,
                    agency: documentToSign?.agency,
                }}
                steps={steps || []}
                currentStepId={EocSigningSteps.REQUIRED_INFO}
            />
            <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
                {({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <Card style={CardStyles.SQUARE} shadow={CardShadow.NONE} className={styles.section}>
                            <SectionHeader text="Please answer the following" />
                            <Note collapsible={false} expanded={true} title="Instructions">
                                <p className={styles.firstInstruction}>
                                    <b>{documentToSign?.agency.details.agencyName}</b> requires the following
                                    information to finalise the creation of <b>{documentToSign?.documentTitle}</b>
                                </p>
                                <p>
                                    Your answers will appear on the document for your review and signing once you
                                    progress to the next screen.
                                </p>
                            </Note>
                            <Typography type={TypographyTypes.H2} text="Instructions from the Sender" />
                            <div className={styles.documentDescription}>{documentToSign?.descriptionText}</div>
                        </Card>
                        <Card style={CardStyles.SQUARE} shadow={CardShadow.NONE} className={styles.section}>
                            {clientCustomPlaceholders.map((placeholder, index) => (
                                <TextInput
                                    key={index}
                                    name={placeholder.id}
                                    label={placeholder.label}
                                    dataTestId={`signer-placeholder-${index}`}
                                />
                            ))}
                        </Card>
                        <Card style={CardStyles.SQUARE} shadow={CardShadow.SMALL} className={styles.footer}>
                            <div className={styles.footerSection}>
                                {isGeneratingPdf && (
                                    <Typography className={styles.buildingText} text="Building PDF for signing..." />
                                )}
                                <Button
                                    buttonStyle={ButtonStyle.PRIMARY}
                                    className={styles.submitButton}
                                    disabled={!valid || isGeneratingPdf || !documentToSign}
                                    type="submit"
                                >
                                    Next
                                    {isGeneratingPdf && <div className={styles.spinner} />}
                                </Button>
                            </div>
                        </Card>
                    </form>
                )}
            </Form>
        </div>
    );
};

export default ClientCustomPlaceholdersForm;
