import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps } from 'react-final-form';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import styles from './TextInput.module.scss';
import { getFormError } from '../../formUtils';

import Icon, { Icons } from '../Icon/Icon';

type TextInputProps = {
    name: string;
    label?: string;
    className?: string;
    labelClassName?: string;
    required?: boolean;
    validateOnTouch?: boolean;
    placeholder?: string;
    showClearButton?: boolean;
    disabled?: boolean;
    type?: string;
    autoFocus?: boolean;
    dataTestId?: string;
};

type TextInputComponentProps = FieldRenderProps<string, HTMLElement> & TextInputProps;

const TextInputComponent: React.FC<TextInputComponentProps> = ({
    input,
    meta,
    label,
    className,
    labelClassName,
    endButton,
    required,
    placeholder,
    disabled,
    type,
    autoFocus,
    dataTestId,
    showClearButton = false,
    validateOnTouch = true,
}) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const error = getFormError(meta, validateOnTouch);

    // Workaround for bug with react-final-form and autoFocus
    // https://github.com/final-form/react-final-form/issues/558
    React.useEffect(() => {
        if (autoFocus) {
            inputRef.current?.focus();
        }
    }, [autoFocus]);

    return (
        <div
            className={cx(styles.container, className, {
                [styles.withButton]: !!endButton,
                [styles.withError]: !!error,
                [styles.disabled]: disabled,
            })}
            data-has-error={!!error}
            data-test-id={dataTestId}
        >
            {label && (
                <label className={cx(styles.label, labelClassName)} htmlFor={input.name}>
                    {label + (required ? '*' : '')}
                </label>
            )}
            <div className={styles.inputContainer}>
                <input
                    disabled={disabled}
                    required={required}
                    ref={inputRef}
                    className={styles.input}
                    {...input}
                    placeholder={placeholder}
                    type={type}
                />
                {!!input.value && showClearButton && (
                    <Button
                        onClick={() => {
                            input.onChange('');
                            inputRef.current?.focus();
                        }}
                        type="button"
                        className={cx(styles.endButton, styles.clearButton)}
                        disabled={disabled}
                        buttonStyle={ButtonStyle.TERTIARY}
                    >
                        <Icon className={styles.clearIcon} icon={Icons.CLOSE} />
                    </Button>
                )}
            </div>
            {!!error && <div className={styles.error}>{error}</div>}
        </div>
    );
};

const TextInput: React.FC<TextInputProps> = ({ ...props }) => {
    return <Field component={TextInputComponent} {...props} />;
};

export default TextInput;
