import cx from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hashHistory from '@app/history';
import { ReactComponent as FlkDocument } from '@public/images/icons/white_document.svg';
import { useParams } from 'react-router';
import { ClientUploadedDocumentMeta } from '@app/types/UploadADoc';
import {
    getFirstUnreadDocumentIdOrNull,
    getIsAnyDocumentRead,
    getIsEnforcedSigningOrder,
    getSelectedDocumentId,
} from '@app/selectors/eoc';
import { buildExchangeOfContractsRoute, ExchangeOfContractsRoutes } from '@app/common/constants';
import Icon, { Icons } from '@app/componentLibrary/Icon/Icon';
import Button, { ButtonStyle } from '@app/common/components/Button';
import { setEocSelectedDocumentId } from '@app/actions/eoc/eocActions';
import { removeFileExtension } from '@app/utils';

import styles from './DocumentList.module.scss';

type DocumentListProps = {
    uploadedClientDocs: ClientUploadedDocumentMeta[];
    isDesktop: boolean;
};

export const DocumentList: React.FC<DocumentListProps> = ({ uploadedClientDocs, isDesktop }) => {
    const dispatch = useDispatch();

    const selectedDocumentId = useSelector(getSelectedDocumentId);
    const firstUnreadDocumentId = useSelector(getFirstUnreadDocumentIdOrNull);
    const isAnyDocumentRead = useSelector(getIsAnyDocumentRead);
    const isSigningOrderEnforced = useSelector(getIsEnforcedSigningOrder);
    const { token } = useParams<{ token: string }>();

    const firstUnreadDocumentRef = React.useRef<HTMLButtonElement>(null);

    React.useLayoutEffect(() => {
        if (isAnyDocumentRead && firstUnreadDocumentId) {
            firstUnreadDocumentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [firstUnreadDocumentId, firstUnreadDocumentRef?.current]);

    const signPdf = (uploadedDocId: string) => {
        hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.SIGN, token, uploadedDocId));
    };

    // When enforcing signing order, only previously read documents and the first unread document should be selectable
    const isDocumentSelectable = (uploadedDocument: ClientUploadedDocumentMeta) => {
        if (isSigningOrderEnforced) {
            return uploadedDocument.read || uploadedDocument.uploadedDocumentId === firstUnreadDocumentId;
        }
        return true;
    };

    const getStatusIndicator = (uploadedDocument: ClientUploadedDocumentMeta) => {
        if (uploadedDocument.read) {
            return <Icon className={styles.checkIcon} icon={Icons.CHECK} />;
        } else if (isDesktop && uploadedDocument.uploadedDocumentId === selectedDocumentId) {
            return <Icon className={styles.chevronRightIcon} icon={Icons.CHEVRON_RIGHT} />;
        } else {
            return <div className={styles.endText}>Review</div>;
        }
    };

    return (
        <section className={styles.container}>
            {uploadedClientDocs.map((uploadedDocument) => (
                <Button
                    className={cx(styles.document, {
                        [styles.reviewed]: uploadedDocument.read,
                        [styles.disabled]: !isDocumentSelectable(uploadedDocument),
                        [styles.selected]: isDesktop && uploadedDocument.uploadedDocumentId === selectedDocumentId,
                    })}
                    key={uploadedDocument.uploadedDocumentId}
                    onClick={
                        isDesktop
                            ? () => dispatch(setEocSelectedDocumentId(uploadedDocument.uploadedDocumentId))
                            : () => signPdf(uploadedDocument.uploadedDocumentId)
                    }
                    buttonStyle={ButtonStyle.CARD}
                    buttonRef={
                        uploadedDocument.uploadedDocumentId === firstUnreadDocumentId
                            ? firstUnreadDocumentRef
                            : undefined
                    }
                    disabled={!isDocumentSelectable(uploadedDocument)}
                >
                    <FlkDocument className={styles.startIcon} />
                    <div className={styles.documentContent}>
                        <span className={styles.documentName}>
                            {removeFileExtension(uploadedDocument.documentName)}
                        </span>
                    </div>
                    {getStatusIndicator(uploadedDocument)}
                </Button>
            ))}
        </section>
    );
};
