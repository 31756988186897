export function linkedList() {
    let head, tail;

    return {
        push(value) {
            const item = { value };

            if (!tail) {
                head = tail = item;
            } else {
                tail.next = item;
                tail = item;
            }
        },
        next() {
            if (head) {
                const value = head.value;

                head = head.next;

                if (!head) {
                    tail = null;
                }

                return value;
            }
        }
    }
}

export function calculateVisibility(el) {
    const rect = el.getBoundingClientRect();

    return {
        inViewportRange: rect.bottom > (window.innerHeight * -3) && rect.top < (window.innerHeight * 4),
        visible: rect.top <= window.innerHeight && rect.bottom >= 0
    };
}
