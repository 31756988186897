import React from 'react';
import cx from 'classnames';

import { Icon, Icons } from '@flk/design-system';

import styles from './Steps.module.scss';

export type Step = {
    title: string;
    id: string;
};

type StepsProps = {
    steps: Step[];
    currentStepId: string;
};

const Steps: React.FC<StepsProps> = ({ steps, currentStepId }) => {
    const currentStepIndex = steps.findIndex((step) => step.id === currentStepId);

    return (
        <div className={styles.container}>
            {steps.map((step, index) => {
                const isLastStep = index === steps.length - 1;
                const isComplete = index < currentStepIndex;
                const isCurrentStep = index === currentStepIndex;

                return (
                    <div
                        key={step.id}
                        className={cx(styles.step, {
                            [styles.complete]: isComplete,
                            [styles.current]: isCurrentStep,
                        })}
                    >
                        {isComplete ? (
                            <Icon icon={Icons.CHECK} className={styles.icon} />
                        ) : (
                            <div className={styles.circle} />
                        )}
                        {!isLastStep && <div className={styles.line} />}
                        <div className={styles.title}>{step.title}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default Steps;
