import cx from 'classnames';
import React from 'react';

import { Spinner, SpinnerSizes, SpinnerTypes } from '@flk/design-system';

import styles from './SignaturePanel.module.scss';

type SignaturePanelProps = {
    base64Signature?: string;
    base64WitnessSignature?: string;
    isWitnessSignatureRequired: boolean;
    isChangingSignature?: boolean;
};

export const SignaturePanel: React.FC<SignaturePanelProps> = ({
    base64Signature,
    base64WitnessSignature,
    isWitnessSignatureRequired,
    isChangingSignature,
}) => {
    return (
        <div
            className={cx(styles.signaturePanelContainer, {
                [styles.witnessSignatureRequired]: isWitnessSignatureRequired,
            })}
        >
            <div className={styles.signaturePanelHeading}>
                {isWitnessSignatureRequired && <h2>{isChangingSignature && 'Current '}Witness signature</h2>}
                <h2>{isChangingSignature && 'Current '}Signature</h2>
            </div>
            <div
                className={cx(styles.signaturePanel, {
                    [styles.pending]: !base64Signature || (isWitnessSignatureRequired && !base64WitnessSignature),
                })}
            >
                {isWitnessSignatureRequired && (
                    <div className={styles.signatureContainer}>
                        {!base64WitnessSignature && (
                            <>
                                <Spinner type={SpinnerTypes.QUATERNARY} size={SpinnerSizes.MEDIUM} />
                                <div className={styles.loadingText}>Pending Witness Signature...</div>
                            </>
                        )}
                        {base64WitnessSignature && <img src={base64WitnessSignature} alt="Witness Signature" />}
                    </div>
                )}
                <div className={styles.signatureContainer}>
                    {!base64Signature && (
                        <>
                            <Spinner type={SpinnerTypes.QUATERNARY} size={SpinnerSizes.MEDIUM} />
                            <div className={styles.loadingText}>Pending Signature...</div>
                        </>
                    )}
                    {base64Signature && <img className={styles.signature} src={base64Signature} alt="Signature" />}
                </div>
            </div>
        </div>
    );
};
