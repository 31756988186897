import { uniq } from 'lodash';
import { createSelector } from 'reselect';
import { UadSigningSteps, VerificationStatus } from '../../types/UploadADoc';
import { shouldCompleteCustomPlaceholdersStep } from '../../containers/eoc/utils';
import { Client, ClientType, ExchangeOfContracts } from '../../types/ExchangeOfContracts';
import { ClientUploadedDocumentMeta, UploadedDocument } from '../../types/UploadADoc';
import { Invite } from '../../types';

// Get EOC specific state
export const getEocState = (state: any) => state.exchangeOfContracts;

// Get shared state from documents reducer
export const getDocumentsState = (state: any) => state.documents;

export const getDocumentClient = createSelector([getDocumentsState], (documents): Client => documents.client);

export const getToken = createSelector([getDocumentsState], (documents): string => documents.smsToken);

export const getUploadedDocumentsMeta = createSelector(
    [getDocumentClient],
    (documentClient): ClientUploadedDocumentMeta[] | undefined => documentClient?.uploadedDocumentsMeta
);

export const getDocumentToSign = createSelector(
    [getDocumentsState],
    (documents): ExchangeOfContracts | null => documents.document
);

export const getIsEnforcedSigningOrder = createSelector(
    [getDocumentToSign],
    (documentToSign): boolean | undefined => documentToSign?.enforceSigningOrder
);

export const getIsEnforceSequentialSigning = createSelector(
    [getDocumentToSign],
    (documentToSign): boolean | undefined => documentToSign?.enforceSequentialSigning
);

export const getDocumentToSignIdOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign): string | null => documentToSign?.id || null
);

export const getDocumentToSignStatusOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign): string | null => documentToSign?.status || null
);

export const getDocumentToSignConfirmationTypeOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign): string | null => documentToSign?.confirmationType || null
);

export const getDocumentToSignType = createSelector(
    [getDocumentToSign],
    (documentToSign): string | undefined => documentToSign?.docType
);

export const getDocumentToSignAccountTypeOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign): string | null => documentToSign?.agency?.accountType || null
);

export const getDocumentToSignClientsOrNull = createSelector(
    [getDocumentToSign],
    (documentToSign): Client[] | undefined => documentToSign?.client?.clients
);

export const getDocumentToSignAcknowledgementText = createSelector(
    [getDocumentToSign],
    (documentToSign): string | undefined => documentToSign?.acknowledgementText
);

export const getDocumentToSignTitle = createSelector(
    [getDocumentToSign],
    (documentToSign): string | undefined => documentToSign?.documentTitle
);

export const getIsMulti = createSelector(
    [getDocumentToSign],
    (documentToSign): boolean => (documentToSign?.uploadedDocuments?.length || 0) > 1
);

export const getClientSignaturePages = createSelector(
    [getDocumentToSign],
    (documentToSign): Record<string, Record<number, string[]>> | undefined =>
        documentToSign?.uploadedDocsClientSignaturePages
);

export const getDocumentInvite = createSelector([getDocumentsState], (documents): Invite => documents.invite);

export const getEocErrorMessage = createSelector(
    [getDocumentsState],
    (documents): string | undefined => documents.errorMessage
);

export const getAddingSignatureFromMobile = createSelector(
    [getEocState],
    (eocDocument): boolean => eocDocument.isAddingSignatureFromMobile
);

export const getMobileSignatureAdded = createSelector(
    [getEocState],
    (eocDocument): boolean => eocDocument.isMobileSignatureAdded
);

export const getDocumentComplete = createSelector(
    [getEocState],
    (eocDocument): boolean => eocDocument.isDocumentComplete
);

export const getDesktopSigningStep = createSelector(
    [getEocState],
    (eocDocument): string => eocDocument.desktopSigningStep
);

export const getSelectedDocumentId = createSelector(
    [getEocState],
    (eocDocument): string | null => eocDocument.selectedDocumentId
);

export const getSelectedDocument = createSelector(
    [getDocumentToSign, getSelectedDocumentId],
    (documentToSign, selectedDocumentId): UploadedDocument | undefined =>
        documentToSign?.uploadedDocuments.find((uploadedDocument) => uploadedDocument.id === selectedDocumentId)
);

export const getSelectedDocumentMeta = createSelector(
    [getUploadedDocumentsMeta, getSelectedDocumentId],
    (uploadedDocumentsMeta, selectedDocumentId): ClientUploadedDocumentMeta | undefined =>
        uploadedDocumentsMeta?.find((uploadedDocument) => uploadedDocument.uploadedDocumentId === selectedDocumentId)
);

export const getIsAnyDocumentRead = createSelector(
    [getUploadedDocumentsMeta],
    (uploadedDocumentsMeta): boolean | undefined =>
        uploadedDocumentsMeta?.some((uploadedDocument) => uploadedDocument.read)
);

export const getIsEveryDocumentRead = createSelector(
    [getUploadedDocumentsMeta],
    (uploadedDocumentsMeta): boolean | undefined =>
        uploadedDocumentsMeta?.every((uploadedDocument) => uploadedDocument.read)
);

export const getFirstUnreadDocumentIdOrNull = createSelector(
    [getUploadedDocumentsMeta],
    (uploadedDocumentsMeta): string | null => {
        return uploadedDocumentsMeta?.find((uploadedDocument) => !uploadedDocument.read)?.uploadedDocumentId || null;
    }
);

export const getIsLastUnreadDocument = createSelector(
    [getSelectedDocumentId, getUploadedDocumentsMeta],
    (selectedDocumentId, uploadedDocumentsMeta): boolean | undefined =>
        uploadedDocumentsMeta
            ?.filter((document) => document.uploadedDocumentId !== selectedDocumentId)
            .every((document) => document.read)
);

/**
 * Get the indexes of signature pages for the current client and document
 */
export const getPagesWithSignaturesForCurrentClientAndDocument = createSelector(
    [getClientSignaturePages, getSelectedDocumentId, getDocumentClient],
    (signaturePages, documentId, client): number[] =>
        uniq(signaturePages?.[documentId as string]?.[client?.index as number] || [])
            .map((pageIndexString) => parseInt(pageIndexString))
            .sort((a, b) => a - b)
);

/**
 * Get the page numbers of signature pages for the current client and document
 */
export const getPageNumbersWithSignaturesForCurrentClientAndDocument = createSelector(
    [getClientSignaturePages, getSelectedDocumentId, getDocumentClient],
    (signaturePages, documentId, client): number[] =>
        uniq(signaturePages?.[documentId as string]?.[client?.index as number] || [])
            .map((pageIndexString) => parseInt(pageIndexString) + 1)
            .sort((a, b) => a - b)
);

export const getIsEditingPlaceholders = createSelector(
    [getEocState],
    (eocDocument): boolean => eocDocument.isEditingPlaceholders
);

interface Step {
    title: string;
    id: string;
}

export const getSteps = createSelector([getDocumentToSign], (document): Step[] => {
    const steps: Step[] = [];

    if (document && shouldCompleteCustomPlaceholdersStep(document)) {
        steps.push({
            title: 'Required Info',
            id: UadSigningSteps.REQUIRED_INFO,
        });
    }

    steps.push({
        title: 'Review & Sign',
        id: UadSigningSteps.REVIEW_SIGN,
    });

    return steps;
});

export const getPurchasersInSigningOrder = createSelector([getDocumentToSign], (documentToSign): Client[] | undefined =>
    documentToSign?.client?.clients
        .filter((client) => client.clientType === ClientType.PURCHASER)
        .sort((a, b) => {
            return a.signingOrder - b.signingOrder;
        })
);

export const getVendorsInSigningOrder = createSelector([getDocumentToSign], (documentToSign): Client[] | undefined =>
    documentToSign?.client?.clients
        .filter((client) => client.clientType === ClientType.VENDOR)
        .sort((a, b) => {
            return a.signingOrder - b.signingOrder;
        })
);
