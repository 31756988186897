import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();
import isEmailValidator from 'validator/lib/isEmail';

export const isMobilePhone = (phone) => {
    if (!phone) {
        return true;
    }
    if (phone.length > 0) {
        try {
            const number = phoneUtil.parseAndKeepRawInput(phone.replace(/ /g, ''));
            return phoneUtil.isValidNumber(number);
        } catch (error) {
            return false;
        }
    }
    return true;
};

export const isEmail = (email) => {
    if (!email) {
        return true;
    }
    return isEmailValidator(email);
};

export function isValidWinAnsi(input) {
    // RegEx for printable ASCII (32-126) and extended Windows-1252 characters (128–255)
    const winAnsiRegex = /^[\x20-\x7E\x80-\xFF]*$/;
    return winAnsiRegex.test(input);
}
