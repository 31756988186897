import React, { useState } from 'react';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import Agent from '../modal/Agent.js';
import styles from './Header.module.scss';
import cx from 'classnames';
import AgentV2, { AgentV2Props } from '../modal/AgentV2';
import Steps, { Step } from '../../containers/custom_document/components/steps/Steps';

interface HeaderProps {
    useAgentV2?: boolean;
    agentV2Props?: AgentV2Props;
    steps: Step[];
    currentStepId: string;
}

const DocumentHeader: React.FC<HeaderProps> = ({ useAgentV2, agentV2Props, steps, currentStepId }) => {
    const [question, setQuestion] = useState<boolean>(false);

    return (
        <>
            <header className={cx(styles.header, styles.documentHeader)}>
                {useAgentV2 && agentV2Props?.agent && agentV2Props?.agency ? (
                    <AgentV2 {...agentV2Props} />
                ) : (
                    <Button
                        className={styles.infoButton}
                        buttonStyle={ButtonStyle.ICON}
                        onClick={() => setQuestion(true)}
                    >
                        <Icon icon={Icons.INFO_ICON} className={styles.icon} />
                    </Button>
                )}
            </header>
            {!useAgentV2 && <Agent modalIsOpen={question} hideModal={() => setQuestion(false)} />}
            <Steps steps={steps} currentStepId={currentStepId} />
        </>
    );
};

export default DocumentHeader;
