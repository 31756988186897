import React from 'react';
import { Form } from 'react-final-form';
import ReactModal from 'react-modal';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';
import { useRollbar } from '@rollbar/react';

import { MobileInput, Spinner, SpinnerTypes } from '@flk/design-system';
import { defaultAxiosInstance } from '../../actions/index';
import Button, { ButtonStyle } from '../../common/components/Button';
import Card, { CardShadow, CardStyles } from '../../componentLibrary/Card/Card';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import { ToastTypes } from '../../components/new_designs/Toast';
import useDoubleSendGuard, { clearDoubleSendGuard } from '../../hooks/useDoubleSendGuard';
import useResendCounter from '../../hooks/useResendCounter';
import useToast from '../../hooks/useToast';
import hashHistory from './../../history';
import { SignaturePanel } from './SignaturePanel';
import { isMobilePhone } from '../../validate';
import { getAddingSignatureFromMobile, getDesktopSigningStep, getDocumentClient, getToken } from '@app/selectors/eoc';
import { setEocDesktopSigningStep, updateEocDocumentComplete } from '@app/actions/eoc/eocActions';
import { DesktopSigningStep } from '@app/types/ExchangeOfContracts';

import styles from './DesktopSign.module.scss';

type EocDesktopSignProps = {
    isOpen: boolean;
    close: () => void;
    documentId?: string;
};

export const EocDesktopSign: React.FC<EocDesktopSignProps> = ({ isOpen, close, documentId }) => {
    const rollbar = useRollbar();
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const client = useSelector(getDocumentClient);
    const signingStep = useSelector(getDesktopSigningStep);
    const isAddingSignatureFromMobile = useSelector(getAddingSignatureFromMobile);

    const doubleSendGuard = useDoubleSendGuard();
    const { resendWaitSeconds, startResendCounter } = useResendCounter();

    const [isConfirmingSignature, setIsConfirmingSignature] = React.useState(false);
    const { addNewToast } = useToast();

    const sendSms = async (mobileNumber: string) =>
        doubleSendGuard(() => {
            const data = { mobileNumber, token };
            defaultAxiosInstance
                .post(`/api/mobile-document/send-sign-sms`, data)
                .then(() => {
                    startResendCounter();
                    addNewToast('SMS sent successfully', ToastTypes.SUCCESS);
                })
                .catch(() => {
                    clearDoubleSendGuard();
                    addNewToast('Failed to send SMS', ToastTypes.ERROR);
                });
        });

    const confirmSignature = (payload: {
        signature?: string;
        customDocumentId?: string;
        clientId?: string;
        isDesktopSigned?: boolean;
    }) =>
        doubleSendGuard(() => {
            const data = {
                signature: payload.signature,
                documentId: payload.customDocumentId,
                clientId: payload.clientId,
                token,
                isDesktopSigned: payload.isDesktopSigned,
            };
            const url = `/api/mobile-document/confirm-eoc`;
            setIsConfirmingSignature(true);
            defaultAxiosInstance
                .post(url, data)
                .then(() => {
                    dispatch(updateEocDocumentComplete(true));
                })
                .catch((error: Error) => {
                    clearDoubleSendGuard();
                    setIsConfirmingSignature(false);
                    rollbar.error('Eror occurred when confirming exchange of contracts', error);
                    hashHistory.push('/error');
                });
        });

    const getHeaderText = () => {
        switch (signingStep) {
            case DesktopSigningStep.SCAN_QR_CODE:
                if (!client?.tempSignature) {
                    return 'Great, we are nearly there!';
                } else {
                    return 'Not liking the way your Signature looks?';
                }
            case DesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE:
                return 'Mobile Signing currently in progress...';
            case DesktopSigningStep.CONFIRM_SIGNATURE:
                return 'Confirm Your Signature';
            default:
                return '';
        }
    };

    const getContent = () => {
        switch (signingStep) {
            case DesktopSigningStep.SCAN_QR_CODE:
                return (
                    <>
                        {client?.tempSignature && (
                            <SignaturePanel
                                base64Signature={client.tempSignature.base64SignatureImage}
                                isChangingSignature={true}
                                isWitnessSignatureRequired={false}
                            />
                        )}
                        <h2>
                            {!client?.tempSignature
                                ? 'Sign your document(s) using the options below:'
                                : 'Change your signature using the options below:'}
                        </h2>
                        <div className={styles.qrCodeContainer}>
                            <QRCode
                                value={`${LETS_URL}/#/sign-document/${token}`}
                                viewBox="0 0 256 256"
                                className={styles.qrCode}
                            />
                            <div className={styles.qrCodeSection}>
                                <div className={styles.qrCodeSectionHeader}>via QR Code</div>
                                <ol className={styles.qrCodeInstructions}>
                                    <li>Open the camera app on your mobile device and scan the QR code.</li>
                                    <li>Follow the instructions on the screen to sign your document.</li>
                                </ol>
                            </div>
                        </div>
                        <Form
                            onSubmit={(values) => sendSms(values.phone)}
                            validate={(values) => {
                                if (!isMobilePhone(values.phone)) {
                                    return { phone: 'Please enter a valid mobile number' };
                                }
                            }}
                        >
                            {({ handleSubmit, submitting }) => (
                                <form onSubmit={handleSubmit} className={styles.smsForm}>
                                    <label htmlFor="phone" className={styles.label}>
                                        Or enter your Mobile Number to receive the Signing Link over SMS
                                    </label>
                                    <MobileInput name="phone" containerClassName={styles.mobileInput} />
                                    <Button
                                        buttonStyle={ButtonStyle.PRIMARY}
                                        disabled={submitting || resendWaitSeconds > 0 || isAddingSignatureFromMobile}
                                        type="submit"
                                        className={styles.sendSmsButton}
                                    >
                                        {submitting && <Spinner type={SpinnerTypes.SECONDARY} />}
                                        {resendWaitSeconds === 0 ? 'Send SMS' : `Resend (${resendWaitSeconds})`}
                                    </Button>
                                </form>
                            )}
                        </Form>
                        {client?.tempSignature && (
                            <Button
                                className={styles.useCurrentSignatureButton}
                                buttonStyle={ButtonStyle.SECONDARY}
                                onClick={() => {
                                    dispatch(setEocDesktopSigningStep(DesktopSigningStep.CONFIRM_SIGNATURE));
                                }}
                            >
                                Use current signature
                            </Button>
                        )}
                    </>
                );

            case DesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE:
                return (
                    <>
                        <SignaturePanel isWitnessSignatureRequired={false} />
                    </>
                );
            case DesktopSigningStep.CONFIRM_SIGNATURE:
                return (
                    <>
                        <div>
                            <SignaturePanel
                                base64Signature={client.tempSignature?.base64SignatureImage}
                                isWitnessSignatureRequired={false}
                            />
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button
                                buttonStyle={ButtonStyle.TERTIARY}
                                onClick={() => {
                                    dispatch(setEocDesktopSigningStep(DesktopSigningStep.SCAN_QR_CODE));
                                }}
                            >
                                Change signature
                            </Button>
                            <Button
                                buttonStyle={ButtonStyle.PRIMARY}
                                onClick={() => {
                                    confirmSignature({
                                        signature: client.tempSignature?.base64SignatureImage,
                                        clientId: client.id,
                                        customDocumentId: documentId,
                                        isDesktopSigned: true,
                                    });
                                }}
                                disabled={!client?.tempSignature || isConfirmingSignature}
                                className={styles.confirmSignatureButton}
                            >
                                {isConfirmingSignature && <Spinner type={SpinnerTypes.SECONDARY} />}
                                Complete signing
                            </Button>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <ReactModal
            isOpen={isOpen}
            className={styles.desktopSigningModal}
            overlayClassName={styles.desktopSigningOverlay}
            bodyOpenClassName={styles.scrollLock}
        >
            <Card
                shadow={CardShadow.NONE}
                animate={false}
                style={CardStyles.SQUARE}
                className={styles.desktopSigningContent}
            >
                <div className={styles.header}>
                    <h1>{getHeaderText()}</h1>
                    {signingStep === DesktopSigningStep.SCAN_QR_CODE && (
                        <Button buttonStyle={ButtonStyle.ICON} onClick={close} className={styles.closeButton}>
                            <Icon icon={Icons.CLOSE} className={styles.closeIcon} />
                        </Button>
                    )}
                </div>
                <div className={styles.body}>{getContent()}</div>
            </Card>
        </ReactModal>
    );
};
