import cx from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setDesktopSigningStep } from '../../actions/custom-document/customDocumentActions.js';
import Button, { ButtonStyle, ButtonTheme } from '../../common/components/Button.js';
import { CONFIRMATION_TYPE_SIGN, DocumentType } from '../../common/constants';
import { desktopSigningStep } from '../../reducers/customDocument.js';
import { DEFINE_ME } from '../../types.js';
import CheckBoxItem, { CheckBoxSize } from '../steps/common/CheckBoxItem.js';
import { useAcknowledgePanel } from './hooks/useAcknowledgePanel';
import { MobileSign } from './MobileSign';
import { DesktopSign } from './DesktopSign';
import { EocDesktopSign } from './EocDesktopSign';

import styles from './AcknowledgePanel.module.scss';
import { Agency } from '../../types/Agency.js';
import { Agent } from '../../types/Agent.js';
import { Spinner, SpinnerSizes } from '@flk/design-system';
import { setEocDesktopSigningStep } from '@app/actions/eoc/eocActions.js';

type AcknowledgePanelProps = {
    documentId: string;
    clientId: string;
    className?: string;
    acknowledgementText: string;
    confirmationType: DEFINE_ME;
    isMulti: boolean | undefined;
    isWitnessSignatureRequired: boolean | undefined;
    isDesktop: boolean;
    clientName: string;
    clientOrderIndex?: number;
    agent: Agent;
    agency: Agency;
    documentType: DocumentType.UPLOAD_A_DOC | DocumentType.EXCHANGE_OF_CONTRACTS;
};

const AcknowledgePanel: React.FC<AcknowledgePanelProps> = ({
    documentId,
    clientId,
    className,
    acknowledgementText,
    confirmationType,
    isMulti,
    isWitnessSignatureRequired,
    isDesktop,
    clientName,
    clientOrderIndex,
    agent,
    agency,
    documentType,
}) => {
    const dispatch = useDispatch();
    const {
        hasAcknowledged,
        setHasAcknowledged,
        hasAgreedToSignElectronically,
        setHasAgreedToSignElectronically,
        getConfirmationButtonText,
        getAgreementText,
        acknowledgeDocument,
        isConfirming,
    } = useAcknowledgePanel(documentId, clientId, confirmationType, isMulti, isWitnessSignatureRequired, documentType);

    const acknowledgePanelRef = React.useRef<HTMLDivElement>(null);

    const [isSigning, setIsSigning] = React.useState(false);

    React.useLayoutEffect(() => {
        acknowledgePanelRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, [acknowledgePanelRef.current]);

    const handleConfirm = () => {
        if (confirmationType === CONFIRMATION_TYPE_SIGN) {
            setIsSigning(true);
            if (isDesktop) {
                if (isWitnessSignatureRequired) {
                    dispatch(setDesktopSigningStep(desktopSigningStep.WITNESS_REQUIRED));
                } else {
                    if (documentType === DocumentType.UPLOAD_A_DOC) {
                        dispatch(setDesktopSigningStep(desktopSigningStep.SCAN_QR_CODE));
                    } else if (documentType === DocumentType.EXCHANGE_OF_CONTRACTS) {
                        dispatch(setEocDesktopSigningStep(desktopSigningStep.SCAN_QR_CODE));
                    }
                }
            }
        } else {
            acknowledgeDocument();
        }
    };

    return (
        <div className={cx(styles.container, className)} ref={acknowledgePanelRef}>
            {confirmationType === CONFIRMATION_TYPE_SIGN && (
                <CheckBoxItem
                    className={styles.checkbox}
                    label={getAgreementText()}
                    onClick={() => setHasAgreedToSignElectronically((oldValue) => !oldValue)}
                    checked={hasAgreedToSignElectronically}
                    size={CheckBoxSize.SMALL}
                />
            )}
            <CheckBoxItem
                className={styles.checkbox}
                label={acknowledgementText || 'TODO: EOC acknowledgement text'}
                onClick={() => setHasAcknowledged((oldValue) => !oldValue)}
                checked={hasAcknowledged}
                size={CheckBoxSize.SMALL}
            />

            <Button
                className={styles.confirmButton}
                buttonStyle={ButtonStyle.PRIMARY}
                buttonTheme={ButtonTheme.DARK}
                onClick={handleConfirm}
                disabled={!hasAcknowledged || !hasAgreedToSignElectronically}
            >
                {getConfirmationButtonText()}
                {isConfirming && <Spinner size={SpinnerSizes.MEDIUM} />}
            </Button>
            {isDesktop ? (
                <>
                    {documentType === DocumentType.UPLOAD_A_DOC && (
                        <DesktopSign
                            isOpen={isSigning}
                            isWitnessSignatureRequired={!!isWitnessSignatureRequired}
                            close={() => setIsSigning(false)}
                            customDocumentId={documentId}
                        />
                    )}
                    {documentType === DocumentType.EXCHANGE_OF_CONTRACTS && (
                        <EocDesktopSign isOpen={isSigning} close={() => setIsSigning(false)} documentId={documentId} />
                    )}
                </>
            ) : (
                <MobileSign
                    customDocumentId={documentId}
                    clientId={clientId}
                    confirmationType={confirmationType}
                    isMulti={isMulti}
                    documentTitle={acknowledgementText}
                    isClientWitnessSignRequired={isWitnessSignatureRequired}
                    clientName={clientName}
                    isSigning={isSigning}
                    clientOrderIndex={clientOrderIndex}
                    agent={agent}
                    agency={agency}
                    cancelSigning={() => setIsSigning(false)}
                    documentType={documentType}
                />
            )}
        </div>
    );
};

export { AcknowledgePanel as AcknowledgePanel, MobileSign, useAcknowledgePanel };
