import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import hashHistory from '@app/history';
import CustomFallback from '../custom_document/CustomFallback';
import { redirectToCorrectPage } from './utils';
import { getDocumentClient, getDocumentInvite, getDocumentToSign, getIsEditingPlaceholders } from '@app/selectors/eoc';
import { eocLoginForDocument } from '@app/actions/eoc/eocActions';

type EocContainerProps = {
    children: React.ReactNode;
};

const EocContainer = ({ children }: EocContainerProps) => {
    const dispatch = useDispatch();
    const document = useSelector(getDocumentToSign);
    const invite = useSelector(getDocumentInvite);
    const client = useSelector(getDocumentClient);
    const isEditingPlaceholders = useSelector(getIsEditingPlaceholders);
    const { token } = useParams<{ token: string }>();

    const history = useHistory();
    const pathName = history.location.pathname;
    const oldPathNameRef = React.useRef<string>();

    React.useEffect(() => {
        if (token) {
            if (!document || token !== invite?.token) {
                dispatch(eocLoginForDocument(token));
            }
        } else {
            hashHistory.push('/error');
        }
    }, [token, document]);

    // Run redirectToCorrectPage whenever pathname changes.
    // This prevents the user from navigating to a page they shouldn't be on
    React.useEffect(() => {
        if (document && pathName !== oldPathNameRef.current) {
            oldPathNameRef.current = pathName;
            redirectToCorrectPage(document, invite, client, token, dispatch, isEditingPlaceholders);
        }
    }, [document, invite, client, pathName]);

    if (!document) {
        return <CustomFallback />;
    }

    return children;
};

export default EocContainer;
