import { Agency } from './Agency';
import { Agent } from './Agent';
import { AddressDetails } from './Common';
import { DateString, DEFINE_ME } from './utilityTypes';
import { CustomPlaceholder, UploadedDocument, Client as UadClient, DeliveryType } from './UploadADoc';

export enum EocDesktopSigningStep {
    AGREE_TO_SIGN = 'AGREE_TO_SIGN',
    SCAN_QR_CODE = 'SCAN_QR_CODE',
    ADDING_SIGNATURE_FROM_MOBILE = 'ADDING_SIGNATURE_FROM_MOBILE',
    CONFIRM_SIGNATURE = 'CONFIRM_SIGNATURE',
}

export enum ClientType {
    VENDOR = 'vendor',
    PURCHASER = 'purchaser',
}

export type Client = UadClient & {
    clientType: ClientType;
    signingOrder: number;
};

export type ExchangeOfContracts = {
    id: string;
    locked: boolean;
    visibilityList: string[]; // list of agent IDs
    agent: Agent;
    agency: Agency;
    created: DateString;
    updated: DateString;
    additionalCharges: {
        requiresApproval: boolean;
        bulkSendApprovalStatus: string;
    };
    isSentViaContactList: boolean;
    createdViaEmail: boolean;
    docType: string;
    pdfGeneratedState: string;
    documentTitle: string;
    status: string;
    sentForSigning?: DateString;
    firstSentForSigning: null | DateString;
    documentLink?: DEFINE_ME;
    client?: {
        currentSigningClientOrder?: number;
        id: string;
        clients: Client[];
    };
    documents: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    }[];
    uploadedDocuments: UploadedDocument[];
    address: string;
    addressDetails: AddressDetails;
    auditTrail: DEFINE_ME[];
    acknowledgementText: string;
    confirmationType: string;
    deadline?: number;
    deliveryType?: DeliveryType;
    descriptionText: string;
    executionType: string;
    hasAgentSignatureAnnotations?: boolean;
    acknowledgedDate?: DateString;
    customPlaceholders: CustomPlaceholder[];
    enforceSequentialSigning?: boolean;
    hasClientCustomPlaceholders?: boolean;
    signingClients: string[];
    enforceSigningOrder?: boolean;
    uploadedDocsClientSignaturePages?: {
        [documentId: string]: {
            [clientIndex: string]: string[];
        };
    };
};

export enum DesktopSigningStep {
    AGREE_TO_SIGN = EocDesktopSigningStep.AGREE_TO_SIGN,
    SCAN_QR_CODE = EocDesktopSigningStep.SCAN_QR_CODE,
    ADDING_SIGNATURE_FROM_MOBILE = EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE,
    CONFIRM_SIGNATURE = EocDesktopSigningStep.CONFIRM_SIGNATURE,
}

export enum EocSigningSteps {
    REQUIRED_INFO = 'required_info',
    REVIEW_SIGN = 'review_sign',
}
