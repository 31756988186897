import cx from 'classnames';
import * as React from 'react';
import { ReactComponent as MainLogo } from '../../../public/images/main_logo.svg';
import { leaseStatuses } from '../../config';
import { ExchangeOfContracts } from '../../types/ExchangeOfContracts';

import styles from './EocSuccessPage.module.scss';

type EocSuccessPageProps = {
    documentToSign?: ExchangeOfContracts;
    errorMessage?: string;
    isDocumentComplete?: boolean;
};

const EocSuccessPage: React.FC<EocSuccessPageProps> = ({ documentToSign, errorMessage, isDocumentComplete }) => {
    return (
        <>
            {((!isDocumentComplete && documentToSign?.status !== leaseStatuses.LEASE_STATUS_SENT_SIGNING) ||
                errorMessage) && (
                <div>
                    <div className={cx(styles.logo, styles.logoIndex)}>
                        <MainLogo />
                    </div>

                    <div className={styles.displayMessage}>
                        {documentToSign && (
                            <p>This link is no longer valid, the contract may have already been signed</p>
                        )}
                        {!documentToSign && errorMessage && <p>This link is no longer valid: {errorMessage}</p>}
                    </div>
                </div>
            )}

            {isDocumentComplete && (
                <div>
                    <div className={cx(styles.logo, styles.logoIndex)}>
                        <MainLogo />
                    </div>

                    <div className={styles.displayMessage}>
                        <p>
                            You have successfully signed the contract of sale, the sender will be informed. Thank you.
                        </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default EocSuccessPage;
