import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { ExchangeOfContractsRoutes } from '../../common/constants';
import EocInfo from './EocInfo';
import EocSign from './EocSign';
import { getDocumentComplete } from '@app/selectors/eoc';

import styles from './ExchangeOfContracts.module.scss';

const ExchangeOfContracts = () => {
    const match = useRouteMatch();

    const [isMobile, setIsMobile] = useState(false);
    const isDocumentComplete = useSelector(getDocumentComplete);

    useEffect(() => {
        const checkIsMobile = () => setIsMobile(window.matchMedia('( max-width: 900px )').matches);

        checkIsMobile();

        window.addEventListener('resize', checkIsMobile);

        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    // Render the component for the mobile based on route. We differentiate the route here because
    // both the components should be the same on desktop. There are only two exact routes that match to this
    // component so we test for which one of these we have
    const renderComponentForMobile = () => {
        if (!match.isExact) {
            return null;
        }

        switch (match.path) {
            case ExchangeOfContractsRoutes.DETAILS:
                return <EocInfo />;
            case ExchangeOfContractsRoutes.SIGN:
                return <EocSign />;
            default:
                // We should never end up here, as the routes above are the only routes that will render this component
                return null;
        }
    };

    return isMobile ? (
        renderComponentForMobile()
    ) : (
        <div
            className={cx(styles.desktopContainer, {
                [styles.complete]: isDocumentComplete,
            })}
        >
            <EocInfo isDesktop />
            {!isDocumentComplete && <EocSign isDesktop />}
        </div>
    );
};

export default ExchangeOfContracts;
