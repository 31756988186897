import React, { memo, useEffect, useState } from 'react';
import prettyBytes from 'pretty-bytes';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router';
import cx from 'classnames';

import {
    getEocErrorMessage,
    getDocumentToSignIdOrNull,
    getDocumentToSign,
    getIsLastUnreadDocument,
    getSelectedDocumentId,
    getPageNumbersWithSignaturesForCurrentClientAndDocument,
    getDocumentToSignStatusOrNull,
    getDocumentToSignConfirmationTypeOrNull,
    getDocumentToSignAccountTypeOrNull,
    getSelectedDocument,
    getSelectedDocumentMeta,
    getDocumentClient,
} from '../../selectors/eoc/index';
import hashHistory from '../../history';
import { defaultAxiosInstance } from '../../actions/index';
import { leaseStatuses } from '../../config';
import { removeFileExtension } from '../../utils';
import { DeviceHeightScrollContainer, Spinner, SpinnerSizes } from '@flk/design-system';
import ToggleButton from '../../common/components/ToggleButton';
import Button, { ButtonSize, ButtonStyle } from '../../common/components/Button';
import { ReactComponent as DocumentIcon } from '../../../public/images/icons/document.svg';
import { setEocSelectedDocumentId, updateEocDocumentClient } from '../../actions/eoc/eocActions';
import PdfViewer from '../../components/pdf_viewer/PdfViewer';
import {
    buildExchangeOfContractsRoute,
    CONFIRMATION_TYPE_SIGN,
    ExchangeOfContractsRoutes,
} from '../../common/constants';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import AgentV2 from '../../components/modal/AgentV2';

import styles from './EocSign.module.scss';

const DOCUMENT_SIZE_10_MB = 10 * 1024 * 1024;
const EXTRA_SPACE = 100;

type EocSignProps = {
    isDesktop?: boolean;
};

const EocSign: React.FC<EocSignProps> = ({ isDesktop }) => {
    const { token, uploadedDocId } = useParams<{
        token: string;
        uploadedDocId: string;
    }>();
    const dispatch = useDispatch();

    const document = useSelector(getDocumentToSign);
    const selectedDocumentId = useSelector(getSelectedDocumentId);
    const documentToSignId = useSelector(getDocumentToSignIdOrNull);
    const documentToSignStatus = useSelector(getDocumentToSignStatusOrNull);
    const documentToSignConfirmationType = useSelector(getDocumentToSignConfirmationTypeOrNull);
    const documentToSignAccountType = useSelector(getDocumentToSignAccountTypeOrNull);
    const pagesWithSignatures = useSelector(getPageNumbersWithSignaturesForCurrentClientAndDocument);
    const selectedDocument = useSelector(getSelectedDocument);
    const selectedDocumentMeta = useSelector(getSelectedDocumentMeta);
    const client = useSelector(getDocumentClient);
    const errorMessage = useSelector(getEocErrorMessage);
    const isLastUnreadDocument = useSelector(getIsLastUnreadDocument);
    const [pagesHeight, setPagesHeight] = useState();

    const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [base64LoadErrorError, setBase64LoadErrorError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [downloadPercentage, setDownloadPercentage] = useState<number | null>(null);

    const [isViewSignaturePages, setIsViewSignaturePages] = useState(false);
    const [hasReviewedDocument, setHasReviewedDocument] = useState(false);
    const [hasAgreedToViewLargeDocument, setHasAgreedToViewLargeDocument] = useState(false);
    const scrollTargetRef = React.useRef<HTMLDivElement>(null);

    const shouldShowSignatureToggle = numberOfPages && pagesWithSignatures.length < numberOfPages;

    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);
    const [doesScrollTargetOverflow, setDoesScrollTargetOverflow] = useState(false);

    const [shouldAnimateFloatingModal, setShouldAnimateFloatingModal] = useState(true);

    function shouldShowScrollMessage() {
        if (isViewSignaturePages && isEmpty(pagesWithSignatures)) {
            return false;
        }
        return doesScrollTargetOverflow && !hasReviewedDocument && !hasScrolledToBottom;
    }

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        if (!hasScrolledToBottom) {
            // This +100 is used to remove scroll to message just before reaching the bottom of the page
            setHasScrolledToBottom(
                event.currentTarget.scrollTop + event.currentTarget.offsetHeight + EXTRA_SPACE >
                    event.currentTarget.scrollHeight
            );
        }
    };

    useEffect(() => {
        if (pagesHeight && scrollTargetRef.current) {
            const doesScrollTargetOverflow = pagesHeight > scrollTargetRef.current.clientHeight;
            setDoesScrollTargetOverflow(doesScrollTargetOverflow);
        }
    }, [pagesHeight]);

    function getPdfUrl(uploadedDocId: string) {
        setIsLoading(true);
        defaultAxiosInstance
            .get(`/api/mobile-document/eoc-preview-url/${documentToSignId}/${uploadedDocId}`)
            .then((response: { data: { url: string } }) => {
                if (response) {
                    setIsLoading(false);
                    setPdfUrl(response.data.url);
                }
            })
            .catch(() => {
                setBase64LoadErrorError(
                    'Failed to load PDF, please refresh screen, if this does not resolve the issue, please contact FLK'
                );
            });
    }

    useEffect(() => {
        setHasReviewedDocument(selectedDocumentMeta?.read || false);
    }, [selectedDocumentMeta]);

    useEffect(() => setIsViewSignaturePages(false), [selectedDocumentId]);

    useEffect(() => {
        setIsLoading(false);
        let documentToDisplayId = selectedDocumentId;
        if (uploadedDocId) {
            dispatch(setEocSelectedDocumentId(uploadedDocId));
            documentToDisplayId = uploadedDocId;
        }
        if (documentToSignAccountType && documentToDisplayId) {
            setBase64LoadErrorError(null);
            setNumberOfPages(null);
            getPdfUrl(documentToDisplayId);
        }
    }, [uploadedDocId, selectedDocumentId, documentToSignAccountType]);

    function setViewSignaturePages(toView: boolean) {
        setIsViewSignaturePages(toView);
    }

    function reviewDocument(successCallback?: () => void) {
        if (!isLastUnreadDocument) {
            setIsLoading(true);
        }
        defaultAxiosInstance
            .post(`/api/mobile-document/uploaded-document-read`, {
                customDocumentId: documentToSignId,
                currentClientId: client.id,
                uploadedDocId: selectedDocumentId,
                token,
            })
            .then((response: { data: { updatedClient: any } }) => {
                if (!isLastUnreadDocument) {
                    setIsLoading(false);
                }
                const { updatedClient } = response.data;
                dispatch(updateEocDocumentClient(updatedClient));
                successCallback?.();
            });
    }

    const handlePdfScrollOrClick = () => {
        if (!scrollTargetRef.current) {
            return;
        }

        if (
            scrollTargetRef.current.scrollTop + scrollTargetRef.current.offsetHeight + EXTRA_SPACE >
            scrollTargetRef.current.scrollHeight
        ) {
            setHasScrolledToBottom(true);
        } else {
            setHasScrolledToBottom(false);
            setShouldAnimateFloatingModal(true);
        }
    };

    useEffect(() => {
        if (shouldAnimateFloatingModal && doesScrollTargetOverflow) {
            const timer = setTimeout(() => {
                setShouldAnimateFloatingModal(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [shouldAnimateFloatingModal, doesScrollTargetOverflow]);

    const handleAgreeToSignDocument = () => {
        const selectedDocumentIndex = client?.uploadedDocumentsMeta?.findIndex(
            (document) => document.uploadedDocumentId === selectedDocumentId
        );

        if (selectedDocumentIndex === undefined || selectedDocumentIndex === -1) {
            return;
        }

        const beforeSelectedDocument = client?.uploadedDocumentsMeta?.slice(0, selectedDocumentIndex);
        const afterSelectedDocument = client?.uploadedDocumentsMeta?.slice(selectedDocumentIndex + 1);
        const firstUnreadDocumentBeforeSelectedDocument = beforeSelectedDocument?.find(
            (document) => !document.read
        )?.uploadedDocumentId;
        const firstUnreadDocumentAfterSelectedDocument = afterSelectedDocument?.find(
            (document) => !document.read
        )?.uploadedDocumentId;

        if (isLastUnreadDocument) {
            reviewDocument();
        } else {
            reviewDocument(() =>
                dispatch(
                    setEocSelectedDocumentId(
                        firstUnreadDocumentAfterSelectedDocument || firstUnreadDocumentBeforeSelectedDocument
                    )
                )
            );
            setHasScrolledToBottom(false);
        }
    };

    const ContainerComponent = isDesktop ? 'div' : DeviceHeightScrollContainer;

    return (
        <ContainerComponent
            className={cx(styles.rootContainer, {
                [styles.mobile]: !isDesktop,
            })}
        >
            {documentToSignStatus === leaseStatuses.LEASE_STATUS_SENT_SIGNING && (
                <React.Fragment>
                    {isLoading && <Spinner className={styles.spinner} size={SpinnerSizes.LARGE} />}
                    {pdfUrl && !isLoading && !base64LoadErrorError && !errorMessage && (
                        <div onScroll={handleScroll} className={styles.container} ref={scrollTargetRef}>
                            {!isDesktop && (
                                <div className={styles.header}>
                                    <Button
                                        className={styles.back}
                                        buttonStyle={ButtonStyle.ICON}
                                        onClick={() => {
                                            hashHistory.push(
                                                buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.DETAILS, token)
                                            );
                                        }}
                                    >
                                        <Icon className={styles.backIcon} icon={Icons.BACK} />
                                    </Button>
                                    <h1 className={styles.title}>
                                        {removeFileExtension(selectedDocument?.document.documentName)}
                                    </h1>
                                    <Button
                                        className={styles.download}
                                        buttonStyle={ButtonStyle.ICON}
                                        onClick={() => {
                                            window.open(
                                                `${SERVICE_URL}/api/mobile-document/exchange-of-contracts-preview-inline/${documentToSignId}/${selectedDocumentId}`
                                            );
                                        }}
                                    >
                                        <Icon className={styles.downloadIcon} icon={Icons.DOWNLOAD} />
                                    </Button>
                                    <AgentV2 agent={document?.agent} agency={document?.agency} />
                                </div>
                            )}
                            {shouldShowSignatureToggle && documentToSignConfirmationType === CONFIRMATION_TYPE_SIGN && (
                                <div
                                    className={cx(styles.subHeader, {
                                        [styles.desktop]: isDesktop,
                                    })}
                                >
                                    <ToggleButton
                                        className={styles.toggle}
                                        firstButtonLabel="Full document"
                                        secondButtonLabel="Signature pages only"
                                        isSecondButtonSelected={isViewSignaturePages}
                                        setSecondButtonSelected={setViewSignaturePages}
                                    />
                                    {isDesktop && (
                                        <Button
                                            buttonStyle={ButtonStyle.SECONDARY}
                                            className={styles.downloadButton}
                                            onClick={() => {
                                                window.open(
                                                    `${SERVICE_URL}/api/mobile-document/exchange-of-contracts-preview-inline/${documentToSignId}/${selectedDocumentId}`
                                                );
                                            }}
                                        >
                                            <Icon className={styles.downloadIcon} icon={Icons.DOWNLOAD} />
                                            Download
                                        </Button>
                                    )}
                                </div>
                            )}
                            {selectedDocument?.document && (
                                <div className={styles.content} data-test="pdf-scroll-container">
                                    {selectedDocument?.document?.size <= DOCUMENT_SIZE_10_MB ||
                                    hasAgreedToViewLargeDocument ? (
                                        <>
                                            <PdfViewer
                                                url={pdfUrl}
                                                pageFilter={isViewSignaturePages && pagesWithSignatures}
                                                scrollParent={scrollTargetRef.current}
                                                onDocumentLoaded={(numPages: number) => setNumberOfPages(numPages)}
                                                onDocumentLoadProgress={(percent: number) =>
                                                    setDownloadPercentage(percent)
                                                }
                                                onPagesResize={(pagesElement: {
                                                    clientHeight: React.SetStateAction<undefined>;
                                                }) => {
                                                    if (pagesElement && pagesElement.clientHeight !== pagesHeight) {
                                                        setPagesHeight(pagesElement.clientHeight);
                                                    }
                                                }}
                                                onClick={handlePdfScrollOrClick}
                                            />
                                        </>
                                    ) : (
                                        <div className={styles.largeFilePlaceholder}>
                                            <div className={styles.iconContainer}>
                                                <div className={styles.fileSize}>
                                                    {prettyBytes(selectedDocument?.document?.size)}
                                                </div>
                                                <DocumentIcon className={styles.icon} />
                                            </div>
                                            <span className={styles.text}>
                                                This document is larger than 10mb, save mobile data by connecting to
                                                wifi.
                                            </span>
                                            <Button
                                                onClick={() => setHasAgreedToViewLargeDocument(true)}
                                                buttonStyle={ButtonStyle.PRIMARY}
                                                buttonSize={ButtonSize.SMALL}
                                            >
                                                View now
                                            </Button>
                                        </div>
                                    )}
                                    {downloadPercentage && downloadPercentage > 0 && downloadPercentage < 100 && (
                                        <div className={styles.progressBar}>
                                            <div
                                                className={styles.progressLine}
                                                style={{
                                                    width: `${downloadPercentage}%`,
                                                }}
                                            />
                                            {downloadPercentage}%
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={styles.footer}>
                                {((selectedDocument?.document &&
                                    selectedDocument?.document.size <= DOCUMENT_SIZE_10_MB) ||
                                    hasAgreedToViewLargeDocument) &&
                                    numberOfPages && (
                                        <Button
                                            className={cx(styles.confirm, {
                                                [styles.desktop]: isDesktop,
                                            })}
                                            buttonStyle={ButtonStyle.PRIMARY}
                                            onClick={() => {
                                                if (isDesktop) {
                                                    handleAgreeToSignDocument();
                                                } else {
                                                    reviewDocument(() =>
                                                        hashHistory.push(
                                                            buildExchangeOfContractsRoute(
                                                                ExchangeOfContractsRoutes.DETAILS,
                                                                token
                                                            )
                                                        )
                                                    );
                                                }
                                            }}
                                            disabled={hasReviewedDocument}
                                        >
                                            I Confirm, I Have Reviewed This Document
                                        </Button>
                                    )}
                            </div>
                            {shouldShowScrollMessage() && (
                                <div
                                    className={cx(styles.scrollToBottom, {
                                        [styles.desktop]: isDesktop,
                                        [styles.bounce]: shouldAnimateFloatingModal,
                                    })}
                                >
                                    Next steps at the end of the document
                                    <Button
                                        className={styles.close}
                                        buttonStyle={ButtonStyle.ICON}
                                        onClick={() => {
                                            setHasScrolledToBottom(true);
                                        }}
                                    >
                                        <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    {base64LoadErrorError && (
                        <div className={styles.loadError}>
                            <Icon icon={Icons.ERROR_ICON} className={styles.errorIcon} />
                            <span>{base64LoadErrorError}</span>
                        </div>
                    )}
                </React.Fragment>
            )}
        </ContainerComponent>
    );
};

export default memo(EocSign);
