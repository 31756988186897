import { DeviceHeightScrollContainer } from '@flk/design-system';
import cx from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import { ReactComponent as EraserIcon } from '../../../public/images/icons/icon_eraser.svg';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import { Tag, TagType } from '../../common/components/Tag.js';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import Preloader from './Preloader';
import useResizeObserver from '../pdf_viewer/useResizeObserver.js';
import { getDocumentSmsToken } from '../../selectors/documents/index.js';
import { Agency } from '../../types/Agency';
import { Agent } from '../../types/Agent';
import AgentV2 from '../modal/AgentV2';
import FullWidthModal from './FullWidthModal';
import styles from './SignV2.module.scss';

interface SignProps {
    isOpen: boolean;
    title: string;
    tenantName: string;
    isSignatureModal: boolean;
    subtitle?: string;
    isSendPdfCopy?: boolean;
    confirmButtonHandler: (params: { signature: string }) => void;
    pdfCallback?: (token?: string) => void;
    hideModal: () => void;
    modalClassName?: string;
    isLoading?: boolean;
    clientOrderIndex?: number;
    agent?: Agent;
    agency?: Agency;
}

const SignV2: React.FC<SignProps> = ({
    isOpen,
    title,
    isSendPdfCopy,
    tenantName,
    isSignatureModal,
    confirmButtonHandler,
    pdfCallback,
    hideModal,
    modalClassName,
    isLoading,
    clientOrderIndex = 0,
    agent,
    agency,
}) => {
    const mySignature = useRef<SignaturePad | null>(null);
    const [isSignature, setIsSignature] = useState<boolean>(false);
    const canvasContainerRef = useRef<HTMLDivElement>();
    const canvasContainer = useCallback((node) => {
        if (node !== null) {
            canvasContainerRef.current = node;
            setCanvasDimensions({
                width: node.offsetWidth,
                height: node.offsetHeight,
            });
        }
    }, []);
    const token: string | undefined = useSelector(getDocumentSmsToken);

    useResizeObserver(canvasContainerRef, (entry: HTMLDivElement) => {
        if (entry.offsetWidth !== canvasDimensions.width || entry.offsetHeight !== canvasDimensions.height) {
            setCanvasDimensions({
                width: entry.offsetWidth,
                height: entry.offsetHeight,
            });
        }
    });

    const [canvasDimensions, setCanvasDimensions] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    const eraseSignature = () => {
        const signature = mySignature.current;
        setIsSignature(false);
        signature?.clear();
    };

    const done = () => {
        const signature = mySignature.current;
        const signImage = signature?.getTrimmedCanvas().toDataURL();
        if (!signature?.isEmpty() && signImage) {
            confirmButtonHandler({ signature: signImage });
        } else {
            alert('No initial/signature received, please try again');
        }
    };

    const drawStart = () => {
        setIsSignature(true);
    };

    const emailPdf = () => {
        if (token) {
            pdfCallback?.(token);
        } else {
            pdfCallback?.();
        }
    };

    const closeModal = () => {
        hideModal();
        setIsSignature(false);
    };

    return (
        <FullWidthModal
            isOpen={isOpen}
            shouldBlockBodyScroll={isOpen}
            className={cx(styles.signModal, modalClassName)}
            onRequestClose={closeModal}
        >
            <DeviceHeightScrollContainer className={styles.signDocument}>
                <header className={styles.header}>
                    <Button buttonStyle={ButtonStyle.ICON} className={styles.backButton} onClick={closeModal}>
                        <Icon icon={Icons.BACK} />
                    </Button>

                    <h1>{title || isSignatureModal ? 'Sign your Full Signature below' : 'Please initial below'}</h1>

                    {agent && agency && <AgentV2 agent={agent} agency={agency} />}
                </header>

                <div className={styles.body}>
                    <div className={styles.signerSection}>
                        <Tag
                            className={styles.tenant}
                            text={`Signer ${Number(clientOrderIndex) + 1}`}
                            tagType={TagType.CLIENT1}
                        />
                        <h3 className={styles.title}>{tenantName} (you)</h3>
                    </div>

                    {/* TODO: Continue on next sprint or after meeting */}
                    {/* {!!isSendPdfCopy && (
                        <Button
                            buttonStyle={ButtonStyle.TERTIARY}
                            onClick={emailPdf}
                            className={cx(styles.buttonEmail, styles.strong)}
                        >
                            Email me a copy before signing
                        </Button>
                    )} */}

                    <div
                        data-test="signature-pad-block"
                        className={cx(styles.signaturePadBlock, {
                            [styles.signatureBackground]: !isSignature && isSignatureModal,
                            [styles.initialBackground]: !isSignature && !isSignatureModal,
                        })}
                        ref={canvasContainer}
                    >
                        <SignaturePad
                            onBegin={drawStart}
                            ref={mySignature}
                            penColor="#000000"
                            minWidth={1.5}
                            canvasProps={{
                                width: canvasDimensions.width,
                                height: canvasDimensions.height,
                                className: styles.signCanvas,
                            }}
                        />
                        {isSignature && (
                            <Button
                                buttonStyle={ButtonStyle.CARD}
                                className={styles.buttonErase}
                                onClick={() => eraseSignature()}
                            >
                                <EraserIcon />
                            </Button>
                        )}
                    </div>

                    <Button
                        buttonStyle={ButtonStyle.PRIMARY}
                        className={styles.buttonDone}
                        onClick={done}
                        disabled={!isSignature || isLoading}
                    >
                        Done
                        {isLoading && <Preloader purple />}
                    </Button>
                </div>
            </DeviceHeightScrollContainer>
        </FullWidthModal>
    );
};

export default SignV2;
