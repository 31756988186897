import { FieldMetaState } from 'react-final-form';
/**
 * Get the error for an input, if we have frontend validation, we want to use the shouldBeTouched as this will only validate on submit or when the field was touched
 * @param meta
 * @param shouldBeTouched
 * @returns {*|boolean}
 */
export const getFormError = (meta: FieldMetaState<unknown>, shouldBeTouched = false) => {
    if (meta.error) {
        return shouldBeTouched ? meta.touched && meta.error : meta.error;
    } else if (meta.submitError && !meta.dirtySinceLastSubmit) {
        return meta.submitError;
    }
};
