import { defaultAxiosInstance } from '../index';
import * as EOC from '../../reducers/eoc';
import cookie from 'react-cookie';
import hashHistory from '../../history';
import { redirectToCorrectPage } from '../../containers/eoc/utils';

/**
 *  Async version of the function so that it can be awaited if necessary
 */
export function getTokenAndUpdateEocDocumentAsync(token, history) {
    return (dispatch) => {
        if (!token) {
            token = cookie.load('mobileToken');
        }

        return defaultAxiosInstance.post(`/api/mobile-document/get-document`, { token }).then(
            (result) => {
                dispatch({
                    type: EOC.EOC_UPDATE_DOCUMENT_SUCCESS,
                    result,
                });
            },
            (error) => {
                history?.push('/error');
            }
        );
    };
}

export function eocLoginForDocument(token, removeOldDocument = true) {
    return async (dispatch) => {
        if (!token) {
            token = cookie.load('mobileToken');
        }

        dispatch({
            type: EOC.EOC_LOGIN_REQUEST,
            removeOldDocument,
        });

        try {
            const result = await defaultAxiosInstance.post(`/api/mobile-document/document-login`, {
                token,
            });

            const { document, invite, client } = result.data;
            redirectToCorrectPage(document, invite, client, token, dispatch);
            dispatch({
                type: EOC.EOC_LOGIN_SUCCESS,
                token: { mobileToken: token },
                result,
            });
        } catch (error) {
            dispatch({
                type: EOC.EOC_LOGIN_FAILURE,
                error,
            });
            hashHistory.push('/error');
        }
    };
}

export function triggerLoginForEocManually(token, result) {
    return {
        type: EOC.EOC_LOGIN_SUCCESS,
        token,
        result,
    };
}

export function updateEocDocumentClient(updatedClient) {
    return {
        type: EOC.EOC_UPDATE_DOCUMENT_CLIENT,
        updatedClient,
    };
}

export function updateEocDocumentComplete(isDocumentComplete) {
    return {
        type: EOC.EOC_UPDATE_DOCUMENT_COMPLETE,
        payload: isDocumentComplete,
    };
}

export function updateEocDocumentInvite(updatedInvite) {
    return {
        type: EOC.EOC_UPDATE_DOCUMENT_INVITE,
        updatedInvite,
    };
}

export function setEocDesktopSigningStep(desktopSigningStep) {
    return {
        type: EOC.EOC_SET_DESKTOP_SIGNING_STEP,
        payload: desktopSigningStep,
    };
}

export function setEocPdfGeneratedState(pdfGeneratedState) {
    return {
        type: EOC.EOC_SET_PDF_GENERATED_STATE,
        payload: pdfGeneratedState,
    };
}

export function setEocSelectedDocumentId(documentId) {
    return {
        type: EOC.EOC_SET_SELECTED_DOCUMENT_ID,
        payload: documentId,
    };
}

export function setEocIsEditingPlaceholders(isEditing) {
    return {
        type: EOC.EOC_SET_IS_EDITING_PLACEHOLDERS,
        payload: isEditing,
    };
}

export function updateEocAddingSignatureFromMobile(isAddingSignature) {
    return {
        type: EOC.EOC_UPDATE_ADDING_SIGNATURE_FROM_MOBILE,
        payload: isAddingSignature,
    };
}

export function updateEocMobileSignatureAdded(isMobileSignatureAdded) {
    return {
        type: EOC.EOC_UPDATE_MOBILE_SIGNATURE_ADDED,
        payload: isMobileSignatureAdded,
    };
}
