/**
 * @deprecated This Material UI icon is deprecated. Please use an icon from our design system instead.
 * Contact the design team for the appropriate replacement icon.
 */
import * as React from 'react';
import createMuiIcon from '../createMuiIcon';

export default createMuiIcon(
    
  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
, 'ExpandMore');
