import React, { useEffect } from 'react';
import { useTwilioSyncProvider } from '../components/providers/TwilioSyncProvider';
import { getDocumentMapId, EVENT_ITEM_UPDATED, TWILIO_TIMED_OUT_ERROR_MESSAGE } from './helpers';

export function useDocumentUpdate(agency, callback, dependencies = []) {
    const syncClient = useTwilioSyncProvider();

    useEffect(() => {
        const handleItemUpdated = (event) => {
            callback(event.item);
        };

        let syncMap;
        let cleanedUp = false;

        if (agency && agency.id && syncClient) {
            // Create a local instance of the documents SyncMap
            syncClient
                .map(getDocumentMapId(agency))
                .then((map) => {
                    // Do not setup listener if we have already cleaned up
                    if (!cleanedUp) {
                        syncMap = map;
                        map.on(EVENT_ITEM_UPDATED, (event) => {
                            // Do not execute callback if we have already cleaned up
                            if (!cleanedUp) {
                                handleItemUpdated(event);
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (!error.message.includes(TWILIO_TIMED_OUT_ERROR_MESSAGE)) {
                        console.error(
                            'Something went wrong getting the updated document',
                            {
                                error_message: error.message,
                                status: 'error',
                                env: ENVIRONMENT,
                            },
                            error
                        );
                    }
                });
        }

        return () => {
            if (syncMap) {
                cleanedUp = true;
                syncMap.close();
            }
        };
    }, [syncClient, agency, ...dependencies]);
}
