import React from 'react';
import { useDispatch } from 'react-redux';

import hashHistory from '@app/history';
import { useDocumentUpdate } from '@app/hooks/useDocumentUpdate';
import { PdfGenerationStatus } from '@app/config';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { setEocIsEditingPlaceholders, setEocPdfGeneratedState } from '@app/actions/eoc/eocActions';
import { buildExchangeOfContractsRoute, ExchangeOfContractsRoutes } from '@app/common/constants';

type Params = {
    token?: string;
    document?: ExchangeOfContracts;
    initialState?: boolean;
    onError: (error?: string) => void;
};

const PDF_GENERATION_TIMEOUT_MS = 3 * 60 * 1000;

export const useGeneratingPdf = ({ token, document, initialState, onError }: Params) => {
    const dispatch = useDispatch();
    const [isGeneratingPdf, setIsGeneratingPdf] = React.useState(initialState || false);

    const generationStarted = () => setIsGeneratingPdf(true);

    const generationFailed = (error?: string) => {
        setIsGeneratingPdf(false);
        onError(error);
    };

    React.useEffect(() => {
        // If there is no response from the server after 3 minutes, show an error page
        const timeoutId = setTimeout(() => {
            if (isGeneratingPdf) {
                generationFailed();
            }
        }, PDF_GENERATION_TIMEOUT_MS);
        // Clean up the timeout if component unmounts or isLoading changes
        return () => clearTimeout(timeoutId);
    }, [isGeneratingPdf]);

    useDocumentUpdate(document ? document.agency : null, (item: any) => {
        if (token && document) {
            if (item?.data?.id === document.id) {
                dispatch(setEocPdfGeneratedState(item.data.pdfGeneratedState));
                if (
                    item.data.pdfGeneratedState === PdfGenerationStatus.COMPLETED_WITH_CLIENT_PLACEHOLDERS ||
                    item.data.pdfGeneratedState === PdfGenerationStatus.COMPLETE
                ) {
                    setIsGeneratingPdf(false);
                    dispatch(setEocIsEditingPlaceholders(false));
                    hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.DETAILS, token));
                } else if (
                    item.data.pdfGeneratedState === PdfGenerationStatus.FAILED_WITH_CLIENT_PLACEHOLDERS ||
                    item.data.pdfGeneratedState === PdfGenerationStatus.FAILED
                ) {
                    generationFailed();
                }
            }
        }
    });

    return { isGeneratingPdf, generationStarted, generationFailed };
};
