import React from 'react';
import cx from 'classnames';
import * as Collapsible from '@radix-ui/react-collapsible';

import { CONFIRMATION_TYPE_SIGN } from '@app/common/constants';

import Card from '@app/common/components/Card';
import Button, { ButtonStyle } from '@app/common/components/Button';
import ExpandMore from '@flk-mui-icons/ExpandMore';
import { Tag, getTagTypeByClientNumber } from '@app/common/components/Tag';
import { Typography, TypographyTypes } from '@flk/design-system';
import { Client, ClientType } from '@app/types/ExchangeOfContracts';

import styles from './EocClientsCard.module.scss';

interface SingleSignerContentProps {
    client: Client;
    isCurrentUser: boolean;
    isExpanded: boolean;
    clientLabel: string;
}

interface MultipleSignerContentProps {
    signingClient?: Client;
    clients: Client[];
    clientLabel: string;
    isExpanded: boolean;
}

const getClientTypeLabel = (clientType: ClientType) => {
    switch (clientType) {
        case ClientType.PURCHASER:
            return 'Purchasers';
        case ClientType.VENDOR:
            return 'Vendors';
        default:
            return '';
    }
};

interface ClientsCardProps {
    signingClientId: string;
    clients: Client[];
    documentConfirmationType: string;
    clientType: ClientType;
    initiallyExpanded?: boolean;
}

const EocClientsCard: React.FC<ClientsCardProps> = ({
    signingClientId,
    clients = [],
    documentConfirmationType,
    clientType,
    initiallyExpanded = true,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(initiallyExpanded);
    const clientLabel = documentConfirmationType === CONFIRMATION_TYPE_SIGN ? 'Signer' : 'Reviewer';

    // Find the signing client in the clients array
    const signingClient = clients.find((client) => client.id === signingClientId);

    const hasMultipleClients = clients.length > 1;

    return (
        <Collapsible.Root open={isExpanded}>
            <Card
                className={cx(styles.clientsCard, {
                    [`${styles.cardControl}`]: isExpanded,
                })}
            >
                <div className={styles.header}>
                    <Typography type={TypographyTypes.H5}>{getClientTypeLabel(clientType)}</Typography>
                    {hasMultipleClients && (
                        <Collapsible.Trigger asChild>
                            <Button
                                onClick={() => setIsExpanded((oldValue) => !oldValue)}
                                buttonStyle={ButtonStyle.ICON}
                                className={styles.controlIcon}
                            >
                                <ExpandMore className={isExpanded ? styles.controlIconExpanded : styles.controlIcon} />
                            </Button>
                        </Collapsible.Trigger>
                    )}
                </div>
                {!hasMultipleClients && (
                    <SingleSignerContent
                        client={clients[0]}
                        isCurrentUser={signingClient?.id === clients[0].id}
                        isExpanded={isExpanded}
                        clientLabel={clientLabel}
                    />
                )}
                {hasMultipleClients && (
                    <MultipleSignerContent
                        signingClient={signingClient}
                        clients={clients}
                        isExpanded={isExpanded}
                        clientLabel={clientLabel}
                    />
                )}
            </Card>
        </Collapsible.Root>
    );
};

const SingleSignerContent: React.FC<SingleSignerContentProps> = ({ client, isCurrentUser, clientLabel }) => {
    return (
        <div className={styles.singleClient}>
            <Tag
                className={styles.clientTag}
                text={`${clientLabel} ${client.signingOrder}`}
                tagType={getTagTypeByClientNumber(client.signingOrder)}
                solidBorder
            />
            <span>
                {client.name} {isCurrentUser ? '(you)' : ''}
            </span>
        </div>
    );
};

const MultipleSignerContent: React.FC<MultipleSignerContentProps> = ({
    signingClient,
    clients,
    clientLabel,
    isExpanded,
}) => {
    // Sort clients by signing order if needed

    return (
        <>
            {!isExpanded ? (
                <>
                    {signingClient && (
                        <div className={styles.singleClient}>
                            <Tag
                                className={styles.clientTag}
                                text={`${clientLabel} ${signingClient.signingOrder}`}
                                tagType={getTagTypeByClientNumber(signingClient.signingOrder)}
                                solidBorder
                            />
                            <span>{signingClient.name} (you)</span>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.clientContent}>
                    <Collapsible.Content className={styles.collapsedContent}>
                        {clients.map((client) => (
                            <div key={client.id} className={styles.singleClient}>
                                <Tag
                                    className={styles.clientTag}
                                    text={`${clientLabel} ${client.signingOrder}`}
                                    tagType={getTagTypeByClientNumber(client.signingOrder)}
                                    solidBorder
                                />
                                <span>
                                    {client.name} {client.id === signingClient?.id ? '(you)' : ''}
                                </span>
                            </div>
                        ))}
                    </Collapsible.Content>
                </div>
            )}
        </>
    );
};

export default EocClientsCard;
