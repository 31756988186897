import React from 'react';
import cx from 'classnames';
import '../../sass/card.scss';

interface CardProps {
    children: React.ReactNode;
    className?: string;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(({ children, className }, ref) => {
    return (
        <div ref={ref} className={cx('card', className)}>
            {children}
        </div>
    );
});

Card.displayName = 'Card';

export default Card;
