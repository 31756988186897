import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Document, pdfjs } from 'react-pdf';
import PageContainer from './PageContainer';
import useViewportManager from './useViewportManager';
import useResizeObserver from './useResizeObserver';

// Styles
import './PdfViewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const REFRESH_INTERVAL = 50;

const DOCUMENT_OPTIONS = {
    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`, // cMapUrl Used for foreign characters
    cMapPacked: true,
    // we have had some PDF's that seem to have been built before PDF 1.5 which means it needs the old standard fonts I think
    standardFontDataUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

function PdfViewer({
    url,
    pageFilter,
    scrollParent,
    onDocumentLoaded,
    onDocumentLoadProgress,
    onPagesResize,
    onClick
}) {
    const pagesRef = useRef();
    const refreshTimeoutRef = useRef();
    const [scroll, setScroll] = useState(window.scrollY);
    const [width, setWidth] = useState(0);
    const [pages, setPages] = useState();
    const [pagesForViewing, setPagesForViewing] = useState();
    const vpMgr = useViewportManager(pagesRef);

    const filterPages = useCallback(() => {
        const { pagesForViewing, pages } = vpMgr.filterPages(pageFilter);

        setPagesForViewing(pagesForViewing);
        setPages(pages);
    }, [vpMgr, pageFilter]);

    useEffect(filterPages, [filterPages]);

    useEffect(() => {
        const el = scrollParent || window;
        const onScroll = () => setScroll(el.scrollY || el.scrollTop);

        el.addEventListener('scroll', onScroll);

        return () => el.removeEventListener('scroll', onScroll);
    }, [scrollParent]);

    useResizeObserver(pagesRef, element => {
        onPagesResize?.(element);
        setWidth(element.clientWidth);
    });

    useEffect(() => {
        clearTimeout(refreshTimeoutRef.current);
        refreshTimeoutRef.current = setTimeout(() => setPages(vpMgr.refreshViewport()), REFRESH_INTERVAL);
    }, [vpMgr, scroll, width, pagesForViewing]);

    const pageRendered = useCallback(pageNumber => setPages(vpMgr.pageRendered(pageNumber)), [vpMgr]);

    async function documentLoaded(data) {
        await vpMgr.init(data);
        filterPages();
        onDocumentLoaded?.(data.numPages);
    }

    return (
        <Document
            file={url}
            options={DOCUMENT_OPTIONS}
            onLoadSuccess={documentLoaded}
            onLoadProgress={({ loaded, total }) =>
                total && onDocumentLoadProgress?.(Math.round((loaded / total) * 100))
            }
            onLoadError={console.error}
            onSourceError={console.error}
            loading={<div>Loading File</div>}
            error={<div>ERROR LOADING PDF</div>}
            noData={<div>NO DATA FOR PDF</div>}
        >
            <div ref={pagesRef} className="pdf-viewer__pages" onClick={onClick}>
                {pages &&
                    pagesForViewing.map(i => {
                        const page = pages[i];

                        return (
                            <PageContainer key={page.pageNumber} width={width} {...page} onRendered={pageRendered} />
                        );
                    })}
            </div>
        </Document>
    );
}

export default PdfViewer;
