import React from 'react';
import infoIconUrl from '../../../public/images/icons/infoIcon.svg';
import agencyLogoPlaceholderUrl from '../../../public/images/icons/senderPlaceholder.svg';
import Card from '../../common/components/Card';
import { Tag, TagType } from '../../common/components/Tag';
import Agent from '../../components/modal/Agent';
import '../../sass/senderCard.scss';
import Button, { ButtonStyle } from './Button';

const SenderCard = ({ agencyLogoUrl, agencyName, agentName, showInfoButton }) => {
    const [showAssistance, setShowAssistance] = React.useState(false);
    return (
        <>
            {showInfoButton && <Agent modalIsOpen={showAssistance} hideModal={() => setShowAssistance(false)} />}
            <Card className="sender-card">
                <img
                    className="sender-card__logo"
                    src={agencyLogoUrl || agencyLogoPlaceholderUrl}
                    alt={`${agencyName} logo`}
                />
                <Tag text="Sender" tagType={TagType.SENDER} solidBorder={true} />
                <div className="sender-card__agency">{agencyName}</div>
                <div className="sender-card__agent">{agentName}</div>
                {showInfoButton && (
                    <Button
                        className="sender-card__info-button"
                        buttonStyle={ButtonStyle.ICON}
                        onClick={() => setShowAssistance(true)}
                    >
                        <img className="sender-card__info-button__icon" src={infoIconUrl} alt="" />
                    </Button>
                )}
            </Card>
        </>
    );
};

export default SenderCard;
