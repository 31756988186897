import * as React from 'react';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import Card from '../../common/components/Card';
import * as Collapsible from '@radix-ui/react-collapsible';
import styles from './Note.module.scss';
import cx from 'classnames';
import { CardShadow, CardStyles } from '../../componentLibrary/Card/Card';

interface NoteProps {
    title: string;
    expanded?: boolean;
    removeIcon?: boolean;
    collapsible?: boolean;
    children: React.ReactNode;
    noteBlockClassName?: string;
}

/**
 * @deprecated - Use Note from design system instead
 */
function Note({
    title,
    expanded = false,
    removeIcon = false,
    collapsible = false,
    children,
    noteBlockClassName,
}: NoteProps) {
    const [open, setOpen] = React.useState(expanded);

    return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
            <Card
                className={cx(styles.noteBlock, noteBlockClassName)}
                shadow={CardShadow.NONE}
                style={CardStyles.SQUARE}
            >
                <div className={styles.header}>
                    <div className={styles.titleGroup}>
                        {!removeIcon && (
                            <Button className={styles.infoButton} buttonStyle={ButtonStyle.ICON}>
                                <Icon icon={Icons.INFO_ICON} className={styles.icon} />
                            </Button>
                        )}
                        <h4 className={styles.title}>{title}</h4>
                    </div>
                    {collapsible && (
                        <Collapsible.Trigger asChild>
                            <Button
                                className={cx(styles.expandButton, { [styles.open]: open })}
                                buttonStyle={ButtonStyle.ICON}
                            >
                                <Icon icon={Icons.EXPAND} className={styles.icon} />
                            </Button>
                        </Collapsible.Trigger>
                    )}
                </div>
                <Collapsible.Content>
                    <div className={styles.noticeBlock}>{children}</div>
                </Collapsible.Content>
            </Card>
        </Collapsible.Root>
    );
}

export default Note;
