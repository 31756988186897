import React, { useCallback } from 'react';
import { memo } from 'react';
import { Page } from 'react-pdf';

function PageContainer({ width, pageNumber, dimensions, shouldRenderPage, rendered, onRendered }) {
    const pageRendered = useCallback(() => onRendered(pageNumber), [pageNumber, onRendered]);

    if (!dimensions) {
        return;
    }

    // Invert width and height if the page is rotated 90 or 270 degrees
    const [pdfWidth, pdfHeight] =
        dimensions.rotation % 180 === 0 ? [dimensions.width, dimensions.height] : [dimensions.height, dimensions.width];

    const style = { width: `${width}px`, height: `${Math.floor((width / pdfWidth) * pdfHeight)}px` };

    const classes = ['pdf-viewer__page'];

    if (shouldRenderPage && !rendered) {
        classes.push('pdf-viewer__page--rendering');
    }

    return (
        <div className={classes.join(' ')} style={style}>
            {shouldRenderPage && (
                <Page
                    width={width}
                    renderAnnotationLayer={false}
                    pageNumber={pageNumber}
                    onRenderSuccess={pageRendered}
                />
            )}
        </div>
    );
}

export default memo(PageContainer);
