import { defaultAxiosInstance } from '../../actions/index.js';

export const sendDocumentPdfCopy = async (
    token: string | undefined,
    options: { onSuccess: () => void; onFailure: () => void }
) => {
    if (!token) {
        options.onFailure();
        return;
    }
    try {
        defaultAxiosInstance.post('/api/mobile-document/send-document-pdf-copy', { token });
        options.onSuccess();
    } catch (error) {
        options.onFailure();
    }
};
