import { ToastTypes } from '../components/new_designs/Toast';
import useToast from './useToast';

const useClipboard = () => {
    const { addNewToast } = useToast();

    const copyToClipboard = (text: string, toastMessage = 'Link Copied!') => {
        navigator.clipboard.writeText(text).then(() => {
            addNewToast(toastMessage, ToastTypes.SUCCESS);
        });
    };

    return {
        copyToClipboard,
    };
};

export default useClipboard;
