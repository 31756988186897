import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import hashHistory from '../../history';
import EocCommonWelcome from './CommonWelcome';
import { redirectToCorrectPage } from './utils';
import { getDocumentClient, getDocumentInvite, getDocumentToSign } from '@app/selectors/eoc';
import { defaultAxiosInstance } from '@app/actions';
import { updateEocDocumentInvite } from '@app/actions/eoc/eocActions';
import { setErrorMessage } from '@app/actions/main';

const EocWelcome: React.FC = () => {
    const document = useSelector(getDocumentToSign);
    const invite = useSelector(getDocumentInvite);
    const client = useSelector(getDocumentClient);
    const { token } = useParams<{ token: string }>();
    const dispatch = useDispatch();

    // EocWelcome should only be rendered if the document is loaded
    if (!document) {
        return null;
    }

    const isLoading = !document || !invite;

    const clientName = client?.name.split(' ')[0];

    const handleGetStarted = useMutation(
        async () => {
            const result = await defaultAxiosInstance.post('/api/mobile-document/accept', {
                token: token,
                customDocumentId: document.id,
                nextStep: 1, // TODO: Use an enum?
            });

            return result?.data;
        },
        {
            onError: (error) => {
                dispatch(setErrorMessage(error?.response?.data?.error));
                hashHistory.push('/error');
            },
            onSuccess: (data) => {
                dispatch(updateEocDocumentInvite(data.invite));
                redirectToCorrectPage(document, data.invite, client, token, dispatch);
            },
        }
    );

    const shouldFillOutRequiredInfo =
        document?.hasClientCustomPlaceholders &&
        (!document.enforceSequentialSigning || document.client?.currentSigningClientOrder === 1);

    return (
        <EocCommonWelcome
            isLoading={isLoading}
            document={document}
            clientName={clientName}
            clientType={client.clientType}
            handleGetStarted={() => handleGetStarted.mutate()}
            isLoadingGetStarted={handleGetStarted.isLoading}
            shouldVerifyIdentity={client?.requireProofOfIdentity}
            shouldFillOutRequiredInfo={shouldFillOutRequiredInfo}
        />
    );
};

export default EocWelcome;
