import { useRef } from 'react';
import { calculateVisibility, linkedList } from './utils';

const MAX_RENDERING_COUNT = 3;

function viewportManager(pagesRef) {
    let renderingCount;
    let renderQueue;
    let pages;
    let pagesForViewing;

    function updatePage(pageNumber, fn) {
        const i = pageNumber - 1;
        const page = { ...pages[i] };

        fn(page);

        pages[i] = page;
    }

    function renderNextPage() {
        if (renderingCount < MAX_RENDERING_COUNT) {
            const pageNumber = renderQueue.next();

            if (pageNumber) {
                renderingCount++;
                updatePage(pageNumber, (page) => (page.shouldRenderPage = true));
            }
        }
    }

    return {
        async init(data) {
            const { numPages } = data;

            pages = await Promise.all(
                [...Array(numPages)].map(async (_, i) => {
                    const pageNumber = i + 1;
                    const {
                        view: [, , width, height],
                        rotate,
                    } = await data.getPage(pageNumber);

                    return { pageNumber, dimensions: { width, height, rotation: rotate } };
                })
            );
        },
        filterPages(filter) {
            if (!pages) {
                return {};
            }

            // turn filter into a hash map
            if (Array.isArray(filter)) {
                filter = filter.reduce((r, c) => ({ ...r, [c]: true }), {});
            }

            renderingCount = 0;
            renderQueue = linkedList();
            pagesForViewing = [];

            pages = pages.map((page, i) => {
                if (!filter || filter[page.pageNumber]) {
                    pagesForViewing.push(i);
                    return page;
                }

                return {
                    ...page,
                    inViewportRange: false,
                    shouldRenderPage: false,
                    rendered: false,
                };
            });

            return { pagesForViewing, pages };
        },
        refreshViewport() {
            if (!pages || !pagesRef.current) {
                return pages;
            }

            renderQueue = linkedList();

            const highPriority = [];
            const lowPriority = [];

            [...pagesRef.current.children].forEach((el, i) => {
                const { inViewportRange, visible } = calculateVisibility(el);

                updatePage(pages[pagesForViewing[i]].pageNumber, (page) => {
                    page.inViewportRange = inViewportRange;

                    if (visible && !page.shouldRenderPage) {
                        highPriority.push(page);
                    } else if (page.inViewportRange && !page.shouldRenderPage) {
                        lowPriority.push(page);
                    }

                    if (!page.inViewportRange && page.rendered) {
                        page.shouldRenderPage = false;
                        page.rendered = false;
                    }
                });
            });

            highPriority.concat(lowPriority).forEach((page) => {
                if (renderingCount < MAX_RENDERING_COUNT) {
                    page.shouldRenderPage = true;
                    renderingCount++;
                } else {
                    renderQueue.push(page.pageNumber);
                }
            });

            return [...pages];
        },
        pageRendered(pageNumber) {
            renderingCount--;

            updatePage(pageNumber, (page) => {
                if (page.inViewportRange) {
                    page.rendered = true;
                } else {
                    page.shouldRenderPage = false;
                    page.rendered = false;
                }
            });

            renderNextPage();

            return [...pages];
        },
    };
}

export default function useViewportManager(pagesRef) {
    const instance = useRef();

    if (!instance.current) {
        instance.current = viewportManager(pagesRef);
    }

    return instance.current;
}
