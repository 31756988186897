import React from 'react';
import cx from 'classnames';
import { Field, FieldRenderProps } from 'react-final-form';
import Icon, { Icons } from '../Icon/Icon';

import styles from './LargeCheckbox.module.scss';

interface LargeCheckboxInputProps extends FieldRenderProps<boolean, HTMLElement> {
    label?: string;
    disabled?: boolean;
    dataTest?: string;
}

const LargeCheckboxInput = ({ input, disabled, children, dataTest }: LargeCheckboxInputProps) => {
    return (
        <label
            className={cx(styles.formInputCheckbox, {
                [styles.disabled]: disabled,
                [styles.active]: input.checked,
            })}
            htmlFor={input.name}
        >
            <input id={input.name} type="checkbox" {...input} disabled={disabled} />
            <div
                className={cx(styles.checkIcon, {
                    [styles.active]: input.checked,
                })}
                data-test={dataTest}
            >
                {input.checked && <Icon icon={Icons.CHECK} />}
            </div>
            <div>{children}</div>
        </label>
    );
};

interface LargeCheckboxProps extends FieldRenderProps<boolean, HTMLElement> {
    name: string;
    disabled?: boolean;
    dataTest?: string;
}

const LargeCheckbox = ({ name, children, disabled, dataTest }: LargeCheckboxProps) => {
    return (
        <Field name={name} component={LargeCheckboxInput} type={'checkbox'} disabled={disabled} dataTest={dataTest}>
            {children}
        </Field>
    );
};

export default LargeCheckbox;
