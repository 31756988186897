import React from 'react';
import ReactModal from 'react-modal';

import { useMediaQueryContext } from '@flk/design-system';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import useClipboard from '../../hooks/useClipboard';
import { Agency } from '../../types/Agency';
import { Agent } from '../../types/Agent';
import { formatPhoneForDisplayInternational } from '../../utils.js';

import cx from 'classnames';
import styles from './AgentV2.module.scss';

export type AgentV2Props = {
    agent: Agent;
    agency: Agency;
    className?: string;
};

const AgentV2: React.FC<AgentV2Props> = ({ agent, agency, className }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { copyToClipboard } = useClipboard();
    const { isMobile } = useMediaQueryContext();

    if (!agent || !agency?.details) {
        return null;
    }
    return (
        <>
            <Button
                className={cx(styles.openButton, className)}
                buttonStyle={ButtonStyle.ICON}
                onClick={() => setIsOpen(true)}
            >
                <Icon icon={Icons.INFO_ICON} className={styles.openIcon} />
            </Button>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h1>Sender Contact Details</h1>
                <div className={styles.agentDetails}>
                    {agent.image && <img className={styles.agentImage} src={agent.image} alt="Agent profile picture" />}
                    <div className={styles.agentName}>
                        {agent.firstName} {agent.secondName}
                    </div>
                </div>
                <div className={styles.agencyDetails}>
                    <div className={styles.agencyName}>{agency.details.agencyName}</div>
                    <div className={styles.agencyAddress}>{agency.details.address}</div>
                </div>
                <div className={styles.contactDetails}>
                    <div className={styles.label}>Phone</div>
                    <div className={styles.number}>{formatPhoneForDisplayInternational(agent.contactNumber)}</div>
                    {navigator.clipboard && (
                        <Button
                            className={styles.copy}
                            buttonStyle={ButtonStyle.ICON}
                            onClick={() => copyToClipboard(agent.contactNumber, 'Phone number copied!')}
                        >
                            <Icon className={styles.copyIcon} icon={Icons.COPY} />
                        </Button>
                    )}
                    {isMobile && (
                        <a className={styles.phone} href={`tel:${agent.contactNumber}`}>
                            <Icon className={styles.phoneIcon} icon={Icons.PHONE} />
                        </a>
                    )}
                </div>
                <Button
                    buttonStyle={ButtonStyle.PRIMARY}
                    className={styles.closeButton}
                    onClick={() => setIsOpen(false)}
                >
                    Back
                </Button>
            </ReactModal>
        </>
    );
};

export default AgentV2;
