import cx from 'classnames';
import React from 'react';
import { ReactComponent as ChevronDown } from '../../../public/images/icons/chevronDown.svg';
import Button, { ButtonStyle } from './Button.js';
import Card from './Card';

import styles from './TextCard.module.scss';

type TextCardProps = {
    title?: string;
    body: string;
    className?: string;
};

const TextCard: React.FC<TextCardProps> = ({ title, body, className }) => {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const cardRef = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
        const { current } = cardRef;
        const checkOverflow = () => {
            const hasOverflow = !!current && current.scrollHeight > current.clientHeight;

            setIsOverflowing(hasOverflow);
        };

        if (current) {
            checkOverflow();
        }
    }, [cardRef]);

    return (
        <Card className={styles.container}>
            <div
                className={cx(styles.content, className, {
                    [styles.expanded]: isExpanded,
                })}
                ref={cardRef}
            >
                {title && <h2 className={styles.title}>{title}</h2>}
                <p className={styles.body}>{body}</p>
            </div>
            {(isOverflowing || isExpanded) && (
                <Button
                    buttonStyle={ButtonStyle.TERTIARY}
                    className={cx(styles.button, {
                        [styles.expanded]: isExpanded,
                    })}
                    onClick={() => setIsExpanded((oldValue) => !oldValue)}
                >
                    {isExpanded ? 'Less' : 'More'}
                    <ChevronDown className={styles.icon} />
                </Button>
            )}
        </Card>
    );
};

export { TextCard };
