import React from 'react';

import WitnessInstruction from '../../components/new_designs/WitnessInstruction';
import WitnessSign from '../../components/new_designs/WitnessSign';
import { DEFINE_ME } from '../../types';
import { Witness } from '../../types/UploadADoc';
import { formatDateTime } from '../../utils.js';
import { useAcknowledgePanel } from './hooks/useAcknowledgePanel';
import SignV2 from '../../components/new_designs/SignV2';
import { Agency } from '../../types/Agency';
import { Agent } from '../../types/Agent';
import { sendDocumentPdfCopy } from '../../components/new_designs/signUtils';
import { ToastTypes } from '../../components/new_designs/Toast';
import useToast from '../../hooks/useToast';
import { DocumentType } from '@app/common/constants';

type MobileSignProps = {
    customDocumentId: string;
    clientId: string;
    confirmationType: DEFINE_ME;
    isMulti: boolean | undefined;
    documentTitle: string;
    isClientWitnessSignRequired: boolean | undefined;
    clientName: string;
    isSigning: boolean;
    clientOrderIndex?: number;
    agent?: Agent;
    agency?: Agency;
    cancelSigning: () => void;
    documentType: DocumentType.UPLOAD_A_DOC | DocumentType.EXCHANGE_OF_CONTRACTS;
};

export const MobileSign: React.FC<MobileSignProps> = ({
    customDocumentId,
    clientId,
    confirmationType,
    isMulti,
    documentTitle,
    isClientWitnessSignRequired,
    clientName,
    isSigning,
    clientOrderIndex,
    agent,
    agency,
    cancelSigning,
    documentType,
}) => {
    const { signDocument, isConfirming } = useAcknowledgePanel(
        customDocumentId,
        clientId,
        confirmationType,
        isMulti,
        isClientWitnessSignRequired,
        documentType
    );

    const [isSignModalOpen, setIsSignModalOpen] = React.useState(false);
    const [isWitnessInstructionModalOpen, setIsWitnessInstructionModalOpen] = React.useState(false);
    const [isWitnessSignModalOpen, setIsWitnessSignModalOpen] = React.useState(false);
    const [signature, setSignature] = React.useState<{
        isSigned?: boolean;
        signature?: string;
        signedDateTime?: string;
    }>({});

    const { addNewToast } = useToast();

    const foundWitness = () => {
        setIsWitnessInstructionModalOpen(false);
        setIsSignModalOpen(true);
    };

    function signDocOrOpenWitnessModal(payload: { signature: string }) {
        signDocument(payload, () => setIsSignModalOpen(false));
        const currentDateTime = new Date();
        const signedDateTime = formatDateTime(currentDateTime, 'dd MMM yyyy', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        setSignature({ ...payload, signedDateTime: signedDateTime });
        if (isClientWitnessSignRequired) {
            setIsWitnessSignModalOpen(true);
            return;
        }
    }

    function witnessSignDocument(payload: { signature: string; witness: Witness }) {
        signDocument({ witnessSigned: true, ...payload }, () => setIsWitnessSignModalOpen(false));
    }

    React.useEffect(() => {
        if (isSigning) {
            if (isClientWitnessSignRequired) {
                setIsWitnessInstructionModalOpen(true);
                return;
            }
            setIsSignModalOpen(true);
        }
    }, [isSigning]);

    return (
        <>
            <SignV2
                isOpen={isSignModalOpen}
                confirmButtonHandler={signDocOrOpenWitnessModal}
                hideModal={() => {
                    setIsSignModalOpen(false);
                    cancelSigning();
                }}
                title="Sign Document"
                subtitle={documentTitle}
                tenantName={clientName}
                isSignatureModal={true}
                isSendPdfCopy={true}
                clientOrderIndex={clientOrderIndex}
                agent={agent}
                agency={agency}
                pdfCallback={(token) =>
                    sendDocumentPdfCopy(token, {
                        onSuccess: () => addNewToast('Email with PDF has been sent.', ToastTypes.SUCCESS),
                        onFailure: () => addNewToast('Something went wrong, please try again.', ToastTypes.ERROR),
                    })
                }
                isLoading={isConfirming}
            />
            <WitnessSign
                isOpen={isWitnessSignModalOpen}
                confirmButtonHandler={witnessSignDocument}
                hideModal={() => {
                    setIsWitnessSignModalOpen(false);
                    cancelSigning();
                }}
                title=""
                subtitle=""
                tenantName="Witness"
                documentTitle={documentTitle}
                isSignatureModal={true}
                clientSignature={signature}
                clientName={clientName}
            />
            <WitnessInstruction
                isOpen={isWitnessInstructionModalOpen}
                hideModal={() => {
                    setIsWitnessInstructionModalOpen(false);
                    cancelSigning();
                }}
                onWitnessFound={() => foundWitness()}
            />
        </>
    );
};
