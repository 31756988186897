import React, { useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import Note from '../../components/new_designs/Note';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import Typography, { TypographyTypes } from './Typography';
import FullWidthModal from './FullWidthModal';

import styles from './WitnessInstruction.module.scss';

interface WitnessInstructionProps {
    isOpen: boolean;
    hideModal: () => void;
    onWitnessFound: () => void;
    modalClassName?: string;
}

interface DesktopWitnessInstructionProps {
    onWitnessFound: () => void;
    noWitnessRightNowHandler?: () => void;
    contentClassName?: string;
    modalClassName?: string;
    noteBlockClassName?: string;
    isDesktop?: boolean;
}

const WitnessRequiredInstructionContent: React.FC<DesktopWitnessInstructionProps> = ({
    onWitnessFound,
    noWitnessRightNowHandler,
    contentClassName,
    noteBlockClassName
}) => {
    return (
        <div className={cx(styles.content, contentClassName)}>
            <div className={styles.instructionNote}>
                <Note noteBlockClassName={noteBlockClassName} title="Instructions" expanded={true} collapsible={false}>
                    <p>This document requires a witness signature, so grab a nearby friend or colleague.</p>
                </Note>
            </div>

            <Button className={styles.button} buttonStyle={ButtonStyle.PRIMARY} onClick={onWitnessFound}>
                I found a witness
            </Button>
            <Button className={styles.button} buttonStyle={ButtonStyle.SECONDARY} onClick={noWitnessRightNowHandler}>
                I don't have a witness right now
            </Button>
        </div>
    );
};

const NoWitnessInstructionContent: React.FC<DesktopWitnessInstructionProps> = ({
    onWitnessFound,
    contentClassName,
    isDesktop
}) => {
    return (
        <div className={cx(styles.content, contentClassName)}>
            <Typography text="No Witness" type={TypographyTypes.H1} className={styles.noWitnessHeader} />
            <Typography
                text="If you have no witness, you can revisit the link to come back to this agreement anytime."
                className={styles.noWitnessText}
            />
            <Button
                className={cx(styles.button, {
                    [`${styles.desktopFoundWitnessButton}`]: isDesktop
                })}
                buttonStyle={ButtonStyle.PRIMARY}
                onClick={onWitnessFound}
            >
                I found a witness
            </Button>
        </div>
    );
};

const WitnessInstruction: React.FC<WitnessInstructionProps> = ({
    isOpen,
    hideModal,
    onWitnessFound,
    modalClassName,
}) => {
    const [noWitnessFound, setNoWitnessFound] = useState(false);

    const onClose = () => {
        setNoWitnessFound(false);
        hideModal();
    };

    return (
        <FullWidthModal
            isOpen={isOpen}
            shouldBlockBodyScroll={isOpen}
            className={cx(styles.witnessInstruction, modalClassName)}
            onRequestClose={onClose}
        >
            <div className={styles.witnessInstruction}>
                <Button buttonStyle={ButtonStyle.ICON} className={styles.closeButton} onClick={onClose}>
                    <Icon icon={Icons.CLOSE} />
                </Button>
                {noWitnessFound ? (
                    <NoWitnessInstructionContent
                        contentClassName={styles.noWitnessContent}
                        onWitnessFound={onWitnessFound}
                    />
                ) : (
                    <WitnessRequiredInstructionContent
                        onWitnessFound={onWitnessFound}
                        noWitnessRightNowHandler={() => setNoWitnessFound(true)}
                    />
                )}
            </div>
        </FullWidthModal>
    );
};

export const DesktopWitnessInstruction: React.FC<DesktopWitnessInstructionProps> = ({
    onWitnessFound,
    modalClassName,
    isDesktop
}) => {
    const [noWitnessFound, setNoWitnessFound] = useState(false);

    return (
        <>
            {noWitnessFound ? (
                <Modal
                    isOpen={noWitnessFound}
                    className={styles.desktopNoWitnessModal}
                    onRequestClose={() => setNoWitnessFound(false)}
                    overlayClassName={styles.desktopNoWitnessModalOverlay}
                >
                    <div className={cx(styles.witnessInstruction, styles.desktopNoWitnessModalBody)}>
                        <Button
                            buttonStyle={ButtonStyle.ICON}
                            className={styles.closeButton}
                            onClick={() => setNoWitnessFound(false)}
                        >
                            <Icon icon={Icons.CLOSE} />
                        </Button>
                        <NoWitnessInstructionContent
                            onWitnessFound={() => {
                                setNoWitnessFound(false);
                                onWitnessFound();
                            }}
                            contentClassName={styles.desktopModalContent}
                            isDesktop={isDesktop}
                        />
                    </div>
                </Modal>
            ) : (
                <WitnessRequiredInstructionContent
                    onWitnessFound={onWitnessFound}
                    noWitnessRightNowHandler={() => setNoWitnessFound(true)}
                    contentClassName={styles.desktopContent}
                    noteBlockClassName={isDesktop ? styles.desktopInstructionNote : ''}
                />
            )}
        </>
    );
};

export default WitnessInstruction;
