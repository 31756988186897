import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import SignaturePad from 'react-signature-canvas';
import { ReactComponent as EraserIcon } from '../../../public/images/icons/icon_eraser.svg';
import Button, { ButtonStyle } from '../../common/components/Button.js';
import { Tag, TagType } from '../../common/components/Tag.js';
import Preloader from '../../components/Preloader.js';

import LargeCheckbox from '../../componentLibrary/LargeCheckbox/LargeCheckbox';
import TextInput from '../../componentLibrary/TextInput/TextInput';
import FullWidthModal from './FullWidthModal';
import Typography, { TypographyTypes } from './Typography';
import styles from './WitnessSign.module.scss';

interface WitnessSignProps {
    isOpen: boolean;
    title: string;
    tenantName: string;
    isSignatureModal: boolean;
    subtitle?: string;
    isSendPdfCopy?: boolean;
    confirmButtonHandler: (params: { signature: string; witness: Witness }) => void;
    hideModal: () => void;
    modalClassName?: string;
    isLoading?: boolean;
    clientSignature: any;
    clientName: string;
    documentTitle: string;
}

const form = {
    firstName: '',
    lastName: '',
    witnessAcknowledgement: false,
};

const WitnessSign: React.FC<WitnessSignProps> = ({
    isOpen,
    tenantName,
    isSignatureModal,
    confirmButtonHandler,
    hideModal,
    modalClassName,
    isLoading,
    clientSignature,
    clientName,
    documentTitle,
}) => {
    const witnessSignature = useRef<SignaturePad | null>(null);
    const [isSignature, setIsSignature] = useState<boolean>(false);
    const [isWitnessForm, setIsWitnessForm] = useState<boolean>(true);
    const [values, setValues] = useState<Witness>({});
    const [isValid, setIsValid] = useState(false);
    const eraseSignature = () => {
        const signature = witnessSignature.current;
        setIsSignature(false);
        signature?.clear();
        setIsValid(false);
    };

    const done = () => {
        if (isWitnessForm) {
            setIsWitnessForm(false);
            setIsValid(false);
            return;
        }
        const signature = witnessSignature.current;
        const signImage = signature?.getTrimmedCanvas().toDataURL();
        if (!signature?.isEmpty() && signImage) {
            confirmButtonHandler({ signature: signImage, witness: { ...values } });
        }
        setIsWitnessForm(false);
    };

    const drawStart = () => {
        setIsSignature(true);
        setIsValid(true);
    };

    const closeModal = () => {
        if (isWitnessForm) {
            hideModal();
        }
        setIsSignature(false);
        setIsWitnessForm(true);
        setIsValid(false);
    };

    return (
        <FullWidthModal
            isOpen={isOpen}
            shouldBlockBodyScroll={isOpen}
            className={cx(styles.signModal, modalClassName)}
            onRequestClose={closeModal}
        >
            <div className={styles.sign}>
                {isWitnessForm ? (
                    <>
                        <div className={styles.signHeader}>
                            <Tag className={styles.tenant} text={tenantName} tagType={TagType.CLIENT1} />
                        </div>
                        <div className={styles.witnessForm}>
                            <Form
                                // eslint-disable-next-line @typescript-eslint/no-empty-function
                                onSubmit={() => {}}
                                initialValues={form}
                                render={({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                        <FormSpy
                                            subscription={{ values: true, valid: true }}
                                            onChange={(state) => {
                                                setValues(state.values);
                                                setIsValid(
                                                    state.values.witnessAcknowledgement &&
                                                        state.values.firstName &&
                                                        state.values.lastName
                                                );
                                            }}
                                        />
                                        <Typography
                                            text="Hi, what's your name?"
                                            type={TypographyTypes.H2}
                                            className={styles.formElement}
                                        />
                                        <TextInput
                                            name="firstName"
                                            placeholder="Witness's first name"
                                            className={styles.formElement}
                                            autoFocus={true}
                                        />
                                        <TextInput
                                            name="lastName"
                                            placeholder="Witness's last name"
                                            className={styles.formElement}
                                        />
                                        <div className={styles.formElement}>
                                            <LargeCheckbox
                                                name={'witnessAcknowledgement'}
                                                value={values.witnessAcknowledgement}
                                                text=""
                                            >
                                                <Typography
                                                    text={`I confirm I am present in the same location as <b>${clientName}</b> and I have witnessed them sign the document <b>${documentTitle}</b>`}
                                                />
                                            </LargeCheckbox>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.witnessSignature}>
                            <div className={styles.clientSignature}>
                                <Typography
                                    text={`You're witnessing ${clientName}'s signature`}
                                    type={TypographyTypes.H2}
                                    className={styles.label}
                                />
                                <img src={clientSignature?.signature} />
                                <Typography
                                    text={`${clientName} signed at ${clientSignature?.signedDateTime}`}
                                    type={TypographyTypes.DEFAULT}
                                    className={styles.signedText}
                                />
                            </div>
                            <Typography
                                text="Please sign your full signature below"
                                type={TypographyTypes.H2}
                                className={styles.label}
                            />
                            <div
                                className={cx(styles.signaturePadBlock, {
                                    [styles.signatureBackground]: !isSignature && isSignatureModal,
                                    [styles.initialBackground]: !isSignature && !isSignatureModal,
                                })}
                            >
                                <SignaturePad
                                    onBegin={drawStart}
                                    ref={witnessSignature}
                                    penColor="#000000"
                                    minWidth={1.5}
                                    canvasProps={{
                                        width: window.innerWidth,
                                        height: window.innerHeight - 470,
                                        className: styles.signCanvas,
                                    }}
                                />
                                {isSignature && (
                                    <Button
                                        buttonStyle={ButtonStyle.CARD}
                                        className={styles.buttonErase}
                                        onClick={() => eraseSignature()}
                                    >
                                        <EraserIcon />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </>
                )}
                <div className={styles.signFooter}>
                    <div className={styles.buttonGroup}>
                        <Button buttonStyle={ButtonStyle.PRIMARY} className={styles.cancelButton} onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            buttonStyle={ButtonStyle.TERTIARY}
                            className={styles.buttonDone}
                            onClick={done}
                            disabled={!isValid}
                        >
                            {isLoading && <Preloader inverse />}
                            Done
                        </Button>
                    </div>
                </div>
            </div>
        </FullWidthModal>
    );
};

export default WitnessSign;
