import { DOCUMENT_STATUS_DRAFT, INVITE_STATUS_CANCELLED, PdfGenerationStatus } from '../../config';
import { setErrorMessage } from '../../actions/main.js';
import hashHistory from '../../history.js';
import { buildExchangeOfContractsRoute, ExchangeOfContractsRoutes } from '../../common/constants';
import { Invite } from '../../types';
import { Dispatch } from 'redux';
import { matchPath } from 'react-router';
import { ExchangeOfContracts, Client } from '../../types/ExchangeOfContracts';

const hasAcceptedInvite = (invite: Invite) => {
    return invite.nextStep !== 0;
};

export const shouldCompleteCustomPlaceholdersStep = (document: ExchangeOfContracts) => {
    if (document?.hasClientCustomPlaceholders) {
        return document.client?.currentSigningClientOrder === 1;
    }
    return false;
};

const hasCompletedCustomPlaceholdersStep = (document: ExchangeOfContracts) => {
    return document.pdfGeneratedState === PdfGenerationStatus.COMPLETED_WITH_CLIENT_PLACEHOLDERS;
};

export const redirectToCorrectPage = (
    document: ExchangeOfContracts,
    invite: Invite,
    client: Client,
    token: string,
    dispatch: Dispatch,
    isEditingPlaceholders?: boolean
) => {
    if (document && invite.status === INVITE_STATUS_CANCELLED) {
        dispatch(setErrorMessage('This document was cancelled, wait for the agent to send you a new one'));
        hashHistory.push('/error');
    } else if (document && document.status === DOCUMENT_STATUS_DRAFT) {
        dispatch(
            setErrorMessage('This document is currently being drafted, please wait until you receive a new message')
        );
        hashHistory.push('/error');
    } else if (document && invite && token && client) {
        const shouldRedirectToCustomPlaceholdersStep =
            shouldCompleteCustomPlaceholdersStep(document) &&
            (!hasCompletedCustomPlaceholdersStep(document) || isEditingPlaceholders);

        if (!hasAcceptedInvite(invite)) {
            if (!matchPath(hashHistory.location.pathname, { path: ExchangeOfContractsRoutes.WELCOME, exact: true })) {
                hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.WELCOME, token));
            }
        } else if (shouldRedirectToCustomPlaceholdersStep) {
            if (!matchPath(hashHistory.location.pathname, { path: ExchangeOfContractsRoutes.FORM, exact: true })) {
                hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.FORM, token));
            }
        } else if (
            !(
                matchPath(hashHistory.location.pathname, { path: ExchangeOfContractsRoutes.DETAILS, exact: true }) ||
                matchPath(hashHistory.location.pathname, { path: ExchangeOfContractsRoutes.SIGN, exact: true })
            )
        ) {
            hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.DETAILS, token));
        }
    }
};
