import { useEffect, useRef } from "react";

export default function useResizeObserver(ref, fn) {
    const resizeRef = useRef();

    useEffect(() => {
        if (!resizeRef.current && ref.current) {
            const ro = new ResizeObserver(([entry]) => window.requestAnimationFrame(() => fn(entry.target)));

            ro.observe(ref.current);
            resizeRef.current = ro;

            return () => {
                ro.disconnect();
                resizeRef.current = undefined;
            };
        }
    });
}