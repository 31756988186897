import React from 'react';
import cx from 'classnames';
import 'sass/checkBoxItem.scss';

const CheckBoxSize = {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
};

const CheckBoxColour = {
    DEFAULT: 'DEFAULT',
    BLUE: 'BLUE',
};

const CheckBoxItem = ({
    id,
    label,
    checked,
    disabled,
    notEditable,
    className,
    labelClassName,
    checkmarkClassName,
    onClick,
    size = CheckBoxSize.DEFAULT,
    colour = CheckBoxColour.DEFAULT,
    dataTest,
    children,
}) => {
    return (
        <div
            className={cx('checkbox-details', className, {
                'checkbox-details--small': size === CheckBoxSize.SMALL,
                'checkbox-details--blue': colour === CheckBoxColour.BLUE,
                'checkbox-details--disabled': disabled,
                'checkbox-details--notEditable': notEditable,
            })}
        >
            <div className="checkbox">
                <input
                    type="checkbox"
                    value={true}
                    id={id || label}
                    checked={checked}
                    disabled={disabled || notEditable}
                    onClick={onClick}
                />
                <label htmlFor={id || label}>
                    <span
                        className={cx('checkmark', checkmarkClassName, {
                            [checkmarkClassName ? `${checkmarkClassName}--checked` : '--checked']: checked,
                        })}
                        data-test={dataTest ? `${dataTest}-check-mark` : ''}
                    />
                    <span
                        className={cx('label-text-checkbox', labelClassName)}
                        dangerouslySetInnerHTML={{ __html: label }}
                    />
                </label>
                {children}
            </div>
        </div>
    );
};

export { CheckBoxSize, CheckBoxColour };
export default CheckBoxItem;
