import { EocDesktopSigningStep } from '@app/types/ExchangeOfContracts';

export const EOC_LOGIN_REQUEST = 'EOC_LOGIN_REQUEST';
export const EOC_LOGIN_SUCCESS = 'EOC_LOGIN_SUCCESS';
export const EOC_LOGIN_FAILURE = 'EOC_LOGIN_FAILURE';
export const EOC_UPDATE_ADDING_SIGNATURE_FROM_MOBILE = 'EOC_UPDATE_ADDING_SIGNATURE_FROM_MOBILE';
export const EOC_UPDATE_MOBILE_SIGNATURE_ADDED = 'EOC_UPDATE_MOBILE_SIGNATURE_ADDED';
export const EOC_UPDATE_DOCUMENT_COMPLETE = 'EOC_UPDATE_DOCUMENT_COMPLETE';
export const EOC_UPDATE_DOCUMENT_INVITE = 'EOC_UPDATE_DOCUMENT_INVITE';
export const EOC_SET_DESKTOP_SIGNING_STEP = 'EOC_SET_DESKTOP_SIGNING_STEP';
export const EOC_SET_PDF_GENERATED_STATE = 'EOC_SET_PDF_GENERATED_STATE';
export const EOC_SET_SELECTED_DOCUMENT_ID = 'EOC_SET_SELECTED_DOCUMENT_ID';
export const EOC_SET_IS_EDITING_PLACEHOLDERS = 'EOC_SET_IS_EDITING_PLACEHOLDERS';
export const EOC_UPDATE_DOCUMENT_SUCCESS = 'EOC_UPDATE_DOCUMENT_SUCCESS';
export const EOC_UPDATE_DOCUMENT_CLIENT = 'EOC_UPDATE_DOCUMENT_CLIENT';

const initState = {
    desktopSigningStep: EocDesktopSigningStep.AGREE_TO_SIGN,
    isAddingSignatureFromMobile: false,
    isMobileSignatureAdded: false,
    isWitnessSignRequired: false,
    isDocumentComplete: false,
    selectedDocumentId: null,
    isEditingPlaceholders: false,
};

export default function eocReducer(state = initState, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case EOC_LOGIN_REQUEST: {
            if (action.removeOldDocument) {
                newState.selectedDocumentId = null;
            }
            break;
        }
        case EOC_LOGIN_SUCCESS: {
            const firstUnreadDocumentId = action.result.data.client?.uploadedDocumentsMeta?.find(
                (uploadedDocument) => !uploadedDocument.read
            )?.uploadedDocumentId;
            const lastDocumentId = action.result.data.client?.uploadedDocumentsMeta?.at(-1)?.uploadedDocumentId;
            newState.selectedDocumentId = firstUnreadDocumentId || lastDocumentId;
            break;
        }
        case EOC_UPDATE_ADDING_SIGNATURE_FROM_MOBILE: {
            newState.isAddingSignatureFromMobile = action.payload;
            break;
        }
        case EOC_UPDATE_MOBILE_SIGNATURE_ADDED: {
            newState.isMobileSignatureAdded = action.payload;
            break;
        }
        case EOC_UPDATE_DOCUMENT_COMPLETE: {
            newState.isDocumentComplete = action.payload;
            break;
        }
        case EOC_SET_DESKTOP_SIGNING_STEP: {
            switch (action.payload) {
                case EocDesktopSigningStep.AGREE_TO_SIGN: {
                    newState.desktopSigningStep = EocDesktopSigningStep.AGREE_TO_SIGN;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case EocDesktopSigningStep.SCAN_QR_CODE: {
                    newState.desktopSigningStep = EocDesktopSigningStep.SCAN_QR_CODE;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE: {
                    newState.desktopSigningStep = EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE;
                    newState.isAddingSignatureFromMobile = true;
                    newState.isMobileSignatureAdded = false;
                    break;
                }
                case EocDesktopSigningStep.CONFIRM_SIGNATURE: {
                    newState.desktopSigningStep = EocDesktopSigningStep.CONFIRM_SIGNATURE;
                    newState.isAddingSignatureFromMobile = false;
                    newState.isMobileSignatureAdded = true;
                    break;
                }
            }
            break;
        }
        case EOC_SET_SELECTED_DOCUMENT_ID: {
            newState.selectedDocumentId = action.payload;
            break;
        }
        case EOC_SET_IS_EDITING_PLACEHOLDERS: {
            newState.isEditingPlaceholders = action.payload;
            break;
        }
    }

    return newState;
}
