import { DeviceHeightScrollContainer, useViewportHeight } from '@flk/design-system';
import { getId } from '@app/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setEocDesktopSigningStep,
    updateEocDocumentComplete,
    setEocIsEditingPlaceholders,
} from '../../actions/eoc/eocActions';
import { getTokenAndUpdateEocDocumentAsync } from '../../actions/eoc/eocActions';
import { defaultAxiosInstance } from '../../actions/index';
import Button, { ButtonStyle } from '../../common/components/Button';
import ClientsCard from '../../common/components/ClientsCard';
import SenderCard from '../../common/components/SenderCard';
import { TextCard } from '../../common/components/TextCard';
import { buildExchangeOfContractsRoute, DocumentType, ExchangeOfContractsRoutes } from '../../common/constants';
import Icon, { Icons } from '../../componentLibrary/Icon/Icon';
import AgentV2 from '../../components/modal/AgentV2';
import { leaseStatuses } from '../../config';
import { useDocumentUpdate } from '../../hooks/useDocumentUpdate';
import {
    getEocErrorMessage,
    getDesktopSigningStep,
    getDocumentComplete,
    getDocumentInvite,
    getDocumentToSign,
    getPurchasersInSigningOrder,
    getVendorsInSigningOrder,
} from '../../selectors/eoc/index';
import { getDocumentClient } from '../../selectors/eoc/index';
import { ClientType, EocDesktopSigningStep, ExchangeOfContracts } from '../../types/ExchangeOfContracts';
import hashHistory from './../../history';
import { AcknowledgePanel } from '../custom_document/AcknowledgePanel';
import { useParams } from 'react-router';
import { DocumentList } from './components/DocumentList';
import EocSuccessPage from './EocSuccessPage';

import styles from './EocInfo.module.scss';
import EocClientsCard from './components/EocClientsCard';

type EocInfoProps = {
    isDesktop?: boolean;
};

const EocInfo: React.FC<EocInfoProps> = ({ isDesktop }) => {
    const dispatch = useDispatch();

    const document = useSelector(getDocumentToSign);
    const invite = useSelector(getDocumentInvite);
    const client = useSelector(getDocumentClient);
    const purchasers = useSelector(getPurchasersInSigningOrder);
    const vendors = useSelector(getVendorsInSigningOrder);

    const errorMessage: string | undefined = useSelector(getEocErrorMessage);
    const isDocumentComplete: boolean | undefined = useSelector(getDocumentComplete);
    const signingStep = useSelector(getDesktopSigningStep);
    const { token } = useParams<{ token: string }>();

    // Keeps track of whether or not the document has been signed by the client
    const [currentClientIndex, setCurrentClientIndex] = useState(0);
    const [hasReviewedAllDocuments, setHasReviewedAllDocuments] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useViewportHeight();

    function setHasReviewedAllDocs() {
        setHasReviewedAllDocuments(!!client?.uploadedDocumentsMeta?.every((document) => document?.read));
    }

    /**
     * Hook to listen to updates of documents within an agency
     */
    useDocumentUpdate(
        document ? document.agency : null,
        async (item: { data?: ExchangeOfContracts }) => {
            if (item?.data?.id === document?.id) {
                const isAddingSignatureFromMobile = item.data?.signingClients?.includes(invite.clientId);
                // isSigning false and isAddingSignatureFromMobile true means user signed the document
                if (
                    !isAddingSignatureFromMobile &&
                    signingStep === EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE
                ) {
                    await dispatch(getTokenAndUpdateEocDocumentAsync(token, hashHistory));
                    if (client?.tempSignature?.base64SignatureImage) {
                        dispatch(setEocDesktopSigningStep(EocDesktopSigningStep.CONFIRM_SIGNATURE));
                    }
                } else if (isAddingSignatureFromMobile && signingStep === EocDesktopSigningStep.SCAN_QR_CODE) {
                    dispatch(setEocDesktopSigningStep(EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE));
                }
            }
        },
        [signingStep]
    );

    useEffect(() => {
        setHasReviewedAllDocs();
    }, [client]);

    useEffect(() => {
        if (document) {
            dispatch(getTokenAndUpdateEocDocumentAsync(token, hashHistory));
        }
        // After sign user should not be able to go back and sign again
        hashHistory.listen(() => {
            if (hashHistory.action === 'POP' && isDocumentComplete) {
                hashHistory.goForward();
            }
        });
    }, []);

    useEffect(() => {
        if (client?.tempSignature) {
            dispatch(setEocDesktopSigningStep(EocDesktopSigningStep.CONFIRM_SIGNATURE));
        }
        if (!client?.hasWitnessSignature) {
            dispatch(updateEocDocumentComplete(!!client?.signature));
        }
    }, [client]);

    useEffect(() => {
        if (document && !document.uploadedDocuments) {
            const isSigning = document?.signingClients?.includes(invite.clientId);
            if (isSigning) {
                dispatch(setEocDesktopSigningStep(EocDesktopSigningStep.ADDING_SIGNATURE_FROM_MOBILE));
            }
        }
        // find current clients index number so it can be shows on document
        if (document?.client) {
            setCurrentClientIndex(document.client.clients.findIndex((client) => getId(client) === invite.clientId));
        }
    }, [document]);

    function getDocumentsLabel() {
        return client?.uploadedDocumentsMeta?.length && client?.uploadedDocumentsMeta?.length > 1
            ? 'documents'
            : 'document';
    }

    function backToForm() {
        setIsLoading(true);
        defaultAxiosInstance
            .post(`/api/mobile-document/uploaded-all-document-unread`, {
                exchangeOfContractsId: document?.id,
                currentClientId: client.id,
                token,
            })
            .then(() => {
                setIsLoading(false);
                dispatch(setEocIsEditingPlaceholders(true));
                hashHistory.push(buildExchangeOfContractsRoute(ExchangeOfContractsRoutes.FORM, token));
            });
    }

    const ContainerComponent = isDesktop ? 'div' : DeviceHeightScrollContainer;

    const canEditCustomPlaceholders =
        !document?.enforceSequentialSigning || (document?.enforceSequentialSigning && client.signingOrder === 1);

    return (
        <ContainerComponent className={styles.container}>
            {document && document.status === leaseStatuses.LEASE_STATUS_SENT_SIGNING && !isDocumentComplete && (
                <div className={styles.content}>
                    <header className={styles.header}>
                        {document.hasClientCustomPlaceholders &&
                            canEditCustomPlaceholders &&
                            (isDesktop || (!isDesktop && !hasReviewedAllDocuments)) && (
                                <Button
                                    buttonStyle={ButtonStyle.ICON}
                                    className={styles.backButton}
                                    disabled={isLoading}
                                    onClick={() => backToForm()}
                                >
                                    <Icon icon={Icons.BACK} />
                                </Button>
                            )}
                        <h1>Review and sign {getDocumentsLabel()} below</h1>
                        <AgentV2 agent={document.agent} agency={document.agency} />
                    </header>
                    <SenderCard
                        agencyLogoUrl={document.agency.logo}
                        agencyName={document.agency.details.agencyName}
                        agentName={document.agent.fullName}
                    />
                    <section className={styles.section}>
                        <h2>Message from the Sender</h2>
                        <TextCard className={styles.text} body={document.descriptionText} />
                    </section>
                    <div className={styles.separator} />
                    <section className={styles.section}>
                        <h2>Recipients</h2>
                        {!!purchasers?.length && (
                            <EocClientsCard
                                signingClientId={client.id}
                                clients={purchasers}
                                documentConfirmationType={document.confirmationType}
                                clientType={ClientType.PURCHASER}
                            />
                        )}
                        {!!vendors?.length && (
                            <EocClientsCard
                                signingClientId={client.id}
                                clients={vendors}
                                documentConfirmationType={document.confirmationType}
                                clientType={ClientType.VENDOR}
                            />
                        )}
                    </section>
                    <div className={styles.separator} />
                    <section className={styles.section}>
                        <h2>Documents</h2>
                        {client.uploadedDocumentsMeta?.length && client.uploadedDocumentsMeta?.length > 0 && (
                            <DocumentList isDesktop={!!isDesktop} uploadedClientDocs={client.uploadedDocumentsMeta} />
                        )}
                        {/* Final acknowledgement once all documents have been approved */}
                        {hasReviewedAllDocuments ? (
                            <AcknowledgePanel
                                confirmationType={document.confirmationType}
                                acknowledgementText={document.acknowledgementText}
                                isMulti={document.uploadedDocuments.length > 1}
                                documentId={document.id}
                                clientId={client.id}
                                isWitnessSignatureRequired={client.hasWitnessSignature}
                                clientName={client.name}
                                isDesktop={!!isDesktop}
                                clientOrderIndex={client.index}
                                agent={document.agent}
                                agency={document.agency}
                                documentType={DocumentType.EXCHANGE_OF_CONTRACTS}
                            />
                        ) : (
                            <p className={styles.helpText}>
                                We will ask for your signature at the final step when all documents have been reviewed
                            </p>
                        )}
                    </section>
                </div>
            )}
            {document && (
                <EocSuccessPage
                    documentToSign={document}
                    errorMessage={errorMessage}
                    isDocumentComplete={isDocumentComplete}
                />
            )}
        </ContainerComponent>
    );
};

export default EocInfo;
