import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateDocumentComplete } from '../../../actions/custom-document/customDocumentActions.js';
import { CONFIRMATION_TYPE_ACKNOWLEDGE, CONFIRMATION_TYPE_SIGN, DocumentType } from '../../../common/constants';
import { DEFINE_ME } from '../../../types';
import { useMutation } from '@tanstack/react-query';
import { defaultAxiosInstance } from '../../../actions/index.js';
import { getDocumentSmsToken } from '../../../selectors/documents/index.js';
import useToast from '../../../hooks/useToast';
import { ToastTypes } from '../../../components/new_designs/Toast';
import { Witness } from '../../../types/UploadADoc.js';
import { useRollbar } from '@rollbar/react';
import { updateEocDocumentComplete } from '@app/actions/eoc/eocActions.js';

export const useAcknowledgePanel = (
    customDocumentId: string,
    clientId: string,
    confirmationType: DEFINE_ME,
    isMulti: boolean | undefined,
    isClientWitnessSignRequired: boolean | undefined,
    documentType: DocumentType.UPLOAD_A_DOC | DocumentType.EXCHANGE_OF_CONTRACTS
) => {
    const rollbar = useRollbar();
    const dispatch = useDispatch();
    const token: string = useSelector(getDocumentSmsToken);
    const { addNewToast } = useToast();

    const confirmDocumentMutation = useMutation({
        mutationFn: (data: {
            customDocumentId: string;
            clientId: string;
            token: string;
            signature?: DEFINE_ME;
            witnessSigned?: boolean;
            witness?: DEFINE_ME;
        }) => {
            const apiUrl =
                documentType === DocumentType.UPLOAD_A_DOC
                    ? '/api/mobile-document/confirm-custom-document'
                    : '/api/mobile-document/confirm-eoc';
            return defaultAxiosInstance.post(apiUrl, data);
        },
        onError: (error: Error) => {
            addNewToast('An error occurred, please try again.', ToastTypes.ERROR);
            rollbar.error('Eror occurred when confirming custom document', error);
        },
    });

    const [hasAcknowledged, setHasAcknowledged] = React.useState(false);
    // This is true by default for acknowledging documents
    const [hasAgreedToSignElectronically, setHasAgreedToSignElectronically] = React.useState(
        confirmationType === CONFIRMATION_TYPE_ACKNOWLEDGE
    );

    const getConfirmationButtonText = () => {
        const confirmationTypeText = confirmationType === CONFIRMATION_TYPE_SIGN ? 'Sign' : 'Acknowledge';
        const documentText = isMulti ? 'all documents' : 'this document';
        return confirmationTypeText + ' ' + documentText;
    };

    const getAgreementText = () => {
        const documentText = isMulti ? 'the above documents' : 'this document';
        const signatureText = isMulti ? 'on all documents' : 'on the document';
        return `I agree to sign ${documentText} electronically, and my signature can be inserted in the specified locations ${signatureText}`.trimEnd();
    };

    function acknowledgeDocument() {
        confirmDocumentMutation.mutate(
            {
                customDocumentId,
                clientId,
                token,
            },
            {
                onSuccess: () => {
                    if (documentType === DocumentType.UPLOAD_A_DOC) {
                        dispatch(updateDocumentComplete(true));
                    } else if (documentType === DocumentType.EXCHANGE_OF_CONTRACTS) {
                        dispatch(updateEocDocumentComplete(true));
                    }
                },
            }
        );
    }

    function signDocument(
        payload: { signature: string; witnessSigned?: boolean; witness?: Witness },
        onSuccess: () => void
    ) {
        confirmDocumentMutation.mutate(
            {
                customDocumentId,
                signature: payload.signature,
                clientId,
                witnessSigned: payload.witnessSigned,
                witness: payload.witness,
                token,
            },
            {
                onSuccess: () => {
                    if (documentType === DocumentType.UPLOAD_A_DOC) {
                        if (!isClientWitnessSignRequired || (isClientWitnessSignRequired && payload.witnessSigned)) {
                            dispatch(updateDocumentComplete(true));
                        }
                    } else if (documentType === DocumentType.EXCHANGE_OF_CONTRACTS) {
                        dispatch(updateEocDocumentComplete(true));
                    }
                    onSuccess();
                },
            }
        );
    }

    return {
        hasAcknowledged,
        setHasAcknowledged,
        hasAgreedToSignElectronically,
        setHasAgreedToSignElectronically,
        getConfirmationButtonText,
        getAgreementText,
        acknowledgeDocument,
        signDocument,
        isConfirming: confirmDocumentMutation.isLoading,
    };
};
