import { defaultAxiosInstance } from './../index';
import * as Custom from './../../reducers/customDocument';
import cookie from 'react-cookie';
import * as Documents from '../../reducers/documents';
import hashHistory from '../../history';
import { redirectToCorrectPage } from '../../containers/custom_document/utils';

export function mobileLoginForCustomDocument(token, removeOldDocument = true) {
    return async (dispatch) => {
        if (!token) {
            token = cookie.load('mobileToken');
        }

        dispatch({
            type: Custom.CUSTOM_LOGIN_REQUEST,
            removeOldDocument,
        });

        try {
            const result = await defaultAxiosInstance.post(`/api/mobile-document/custom-document-login`, { token });

            const { document, invite, client } = result.data;
            redirectToCorrectPage(document, invite, client, token, dispatch);
            dispatch({
                type: Custom.CUSTOM_LOGIN_SUCCESS,
                token,
                result,
            });
        } catch (error) {
            dispatch({
                type: Custom.CUSTOM_LOGIN_FAILURE,
                error,
            });
            hashHistory.push('/error');
        }
    };
}

export function triggerLoginForCustomDocumentManually(token, result) {
    return {
        type: Custom.CUSTOM_LOGIN_SUCCESS,
        token,
        result,
    };
}

export function updateDocumentClient(updatedClient) {
    return {
        type: Documents.UPDATE_DOCUMENT_CLIENT,
        updatedClient,
    };
}

export function updateDocumentComplete(isDocumentComplete) {
    return {
        type: Custom.UPDATE_DOCUMENT_COMPLETE,
        payload: isDocumentComplete,
    };
}

export function updateDocumentInvite(updatedInvite) {
    return {
        type: Custom.UPDATE_DOCUMENT_INVITE,
        updatedInvite,
    };
}

export function setClientVerified() {
    return {
        type: Documents.CLIENT_VERIFIED,
    };
}

export function setDesktopSigningStep(desktopSigningStep) {
    return {
        type: Custom.SET_DESKTOP_SIGNING_STEP,
        payload: desktopSigningStep,
    };
}

export function setSelectedDocumentId(selectedDocumentId) {
    return {
        type: Custom.SET_SELECTED_DOCUMENT_ID,
        payload: selectedDocumentId,
    };
}

export function getTokenAndUpdateDocumentAsync(token, history) {
    return (dispatch) => {
        if (!token) {
            token = cookie.load('mobileToken');
        }

        dispatch({ type: Documents.UPDATE_DOCUMENT_REQUEST });

        return defaultAxiosInstance.post(`/api/mobile-document/get-document`, { token }).then(
            (result) => {
                dispatch({
                    type: Documents.UPDATE_DOCUMENT_SUCCESS,
                    result,
                });
            },
            (error) => {
                history?.push('/error');
            }
        );
    };
}

export function setIsEditingPlaceholders(isEditingPlaceholders) {
    return {
        type: Custom.SET_IS_EDITING_PLACEHOLDERS,
        payload: isEditingPlaceholders,
    };
}

export function setPdfGeneratedState(pdfGeneratedState) {
    return {
        type: Custom.SET_PDF_GENERATED_STATE,
        payload: pdfGeneratedState,
    };
}

export function setIsQrCodeTemplate(isQrCodeTemplate) {
    return {
        type: Custom.SET_IS_QR_CODE_TEMPLATE,
        payload: isQrCodeTemplate,
    };
}
